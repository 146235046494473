import { Link } from "react-router-dom"

const AdminMenu = () => {
  return (
    <>
    <div className="menu flex justify-center items-center mt-4 bg-slate-600 rounded-3xl border-2 border-slate-500 py-2">
        <div className="menugrid grid grid-cols-12 gap-4">
            <Link to={"dashboard"} className="col-span-3 py-2 rounded-2xl border-2 border-black bg-slate-900 text-white hover:border-slate-500 transition-all px-4 cursor-pointer flex justify-center items-center">
              <div>
                Dashboard
              </div>
            </Link>
            <Link to={"managecourses"} className="col-span-3 py-2 rounded-2xl border-2 border-black bg-slate-900 text-white hover:border-slate-500 transition-all px-4 cursor-pointer flex justify-center items-center">
              <div>
                Manage Courses
              </div>
            </Link>
            <Link to={"manageenrollment"} className="col-span-3 py-2 rounded-2xl border-2 border-black bg-slate-900 text-white hover:border-slate-500 transition-all px-4 cursor-pointer flex justify-center items-center">
              <div>
                Manage Enrollement
              </div>
            </Link>
            <Link to={"sendannouncement"} className="col-span-3 py-2 rounded-2xl border-2 border-black bg-slate-900 text-white hover:border-slate-500 transition-all px-4 cursor-pointer flex justify-center items-center">
              <div>
                Send Announcement
              </div>
            </Link> 
        </div>
    </div>
    </>
  )
}

export default AdminMenu