import React, { useState } from 'react'
import UnderDevelopment from '../UnderDevelopment'
import { LiaPeopleCarrySolid, LiaTicketAltSolid } from "react-icons/lia";
import { SiTicktick } from "react-icons/si";
import { IoIosArrowForward, IoIosCheckmarkCircleOutline } from "react-icons/io";
import AllEvents from './AllEvents';
import UpcomingEvents from './UpcomingEvents';
import PastEvents from './PastEvents';

 
const Events = () => {

  const [All, setAll] = useState(true);
  const [Upcoming, setUpcoming] = useState(false);
  const [Past, setPast] = useState(false);

  function onClickAll() {
    setAll(true);
    setUpcoming(false);
    setPast(false);
  }
  function onClickUpcoming() {
    setAll(false);
    setUpcoming(true);
    setPast(false);
  }
  function onClickPast() {
    setAll(false);
    setUpcoming(false);
    setPast(true);
  }

  return (
    <>
    <div className='bg-gradient-to-r from-orange-600 to-amber-900 rounded-xl'>
      <div className="flex justify-between items-center">
        <div className='p-4'>
          <h1 className='text-4xl font-bold'>Insiden Events</h1>
          <p>Powered by Strater.in</p>
        </div>
        <div className='p-4'>
          <LiaPeopleCarrySolid className='text-9xl' />
        </div>
      </div>
    </div>
    
    <div className='grid grid-cols-12 mt-4 gap-4 -order-1'>
      
      <div className='col-span-12 md:col-span-8 h-screen'>

        <div className="tab border-b-2 border-slate-700 flex gap-2">
          <div className={`p-2 px-4 cursor-pointer ${All? "bg-orange-600" : null} hover:bg-orange-600 rounded-t-xl transition-all duration-[0.02s]`} onClick={() => onClickAll()}>
            All
          </div>
          <div className={`p-2 px-4 cursor-pointer ${Upcoming? "bg-orange-600" : null} hover:bg-orange-600 rounded-t-xl transition-all duration-[0.02s]`} onClick={() => onClickUpcoming()}>
            Upcoming
          </div>
          <div className={`p-2 px-4 cursor-pointer ${Past? "bg-orange-600" : null} hover:bg-orange-600 rounded-t-xl transition-all duration-[0.02s]`} onClick={() => onClickPast()}>
            Past
          </div>
        </div>

        <div className="content">
          {All? <AllEvents /> : Upcoming? <UpcomingEvents /> : Past? <PastEvents />: <div>No Events</div>}
        </div>       

      </div>

      <div className='md:col-span-4 col-span-12'>
        <div className='bg-slate-800 border-2 border-slate-600 rounded-xl p-4 sticky top-4'>            
          <h1 className='text-2xl font-semibold'>Why attend Insiden Events?</h1>
          <div className='border-t-2 mt-4 border-slate-600'></div>
          <div className='flex items-center gap-2 mt-4'>
              <div>
                <IoIosCheckmarkCircleOutline className='text-2xl text-green-400' />
              </div>
              <div className="text-lg text-slate-300">
                Community Support
              </div>
          </div>
          <div className='flex items-center gap-2 mt-4'>
              <div>
                <IoIosCheckmarkCircleOutline className='text-2xl text-green-400' />
              </div>
              <div className="text-lg text-slate-300">
                Industry Expert
              </div>
          </div>
          <div className='flex items-center gap-2 mt-4'>
              <div>
                <IoIosCheckmarkCircleOutline className='text-2xl text-green-400' />
              </div>
              <div className="text-lg text-slate-300">
                Live Session
              </div>
          </div>
          <div className='flex items-center gap-2 mt-4'>
              <div>
                <IoIosCheckmarkCircleOutline className='text-2xl text-green-400' />
              </div>
              <div className="text-lg text-slate-300">
                Mentorship
              </div>
          </div>
          <div className='flex items-center gap-2 mt-4'>
              <div>
                <IoIosCheckmarkCircleOutline className='text-2xl text-green-400' />
              </div>
              <div className="text-lg text-slate-300">
                Collboration
              </div>
          </div>
          <div className='flex items-center cursor-pointer bg-orange-600 hover:bg-orange-700 rounded-xl mb-2 p-4 justify-center gap-2 mt-4'>
              <div className="text-lg text-slate-300 flex items-center gap-2" onClick={onClickUpcoming}>
                Explore Upcoming                
                <IoIosArrowForward className='animate-ping translate-x-[-8px]'/>
              </div>
          </div>
        </div>
      </div>

    </div>
</>
  )
}

export default Events