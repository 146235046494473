import React from "react";

const Search = () => {
  return (
    <>
      <form action="">
        <input
          type="search"
          name=""
          id=""
          className="rounded-md p-1 bg-transparent outline-none"
          placeholder="Search Insiden"
        />
      </form>
    </>
  );
};

export default Search;
