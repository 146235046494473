import React from 'react'
import { IoIosArrowForward } from 'react-icons/io'
import { Link } from 'react-router-dom'

const CardCourse = (props) => {
  return (
    <div className="onecourse bg-slate-800 p-4 rounded-2xl border-2 border-slate-700  hover:cursor-pointer hover:translate-y-[-2px] transition-all duration-[0.15s] hover:shadow-2xl">
        <div className={`rounded-2xl h-auto flex items-center justify-center overflow-hidden`}>
            <img className='h-fit' src={props.image} alt="" />
        </div>
        <div className="details p-2 font-semibold">
            <h1 className='text-2xl font-semibold line-clamp-2'>{props.courseTitle}</h1> 
            {/* <span>3-Month +</span> */}
            <div className='flex justify-between'>
                <div className='text-green-500 py-2'>{props.duration}+ Months</div>
                <div className='text-gray-500 py-2'>{props.hours}+ Hours</div>
            </div>
        </div>
        <Link to={`../course-study/${props.courseId}`}>
            <div className="button p-2 border border-slate-600 rounded-xl text-white transition-all hover:bg-slate-900 flex justify-center items-center justify-between px-4">                    
                <p className='cursor-pointer text-center text-slate-300'>Continue Studying </p>
                <div className="hover:scale-[1.02] pl-3">
                    <IoIosArrowForward className='animate-ping translate-x-[-8px]'/>
                </div>
            </div>
        </Link>
    </div>
  )
}

export default CardCourse