import { createContext, useContext, useEffect, useState } from "react";
import { ProgressBar } from "react-loader-spinner";

const LoadingContext = createContext();

export function LoadingContextProvider({children}) {

    const [loading, setLoading] = useState(false);

    const ShowLoading = () => {
        return (
            loading && <div className='z-30 fixed h-screen w-full bg-slate-800/50 flex items-center justify-center'>
            <ProgressBar
              visible={true}
              height="80"
              width="80"
              color="orange"
              barColor="orange"
              borderColor="orange"
              ariaLabel="progress-bar-loading"
              wrapperStyle={{}}
              wrapperClass=""
            /> 
          </div>
        )
    }

    return (
        <LoadingContext.Provider 
        value={{loading, setLoading, ShowLoading}} >
            {children}
        </LoadingContext.Provider>
    )
}

export function useLoadingContext() {
    return useContext(LoadingContext);
}