import React from 'react'

const PastEvents = () => {
  return (
    <>

    <div className="grid grid-cols-6 gap-4 hover:translate-y-[-1px] py-4">
        <div className='md:col-span-3 col-span-6 bg-slate-800 rounded-2xl'>
            <div className="image">
            <img src="https://i.imghippo.com/files/T0mgJ1716061887.png" className='rounded-t-xl' alt="" />
            </div>
            <div className='content p-4'>
            fsd
            </div>
        </div>
    </div> 
        
    </>
  )
}

export default PastEvents