import {
  collection,
  getDoc,
  where,
  query,
  getDocs,
  doc,
} from "firebase/firestore";
import React, { useEffect, useState } from "react";
import { db } from "../../firebase";
import { InfinitySpin, MagnifyingGlass, ThreeDots } from "react-loader-spinner";
import { useMobileScreenContext } from "../../context/MobileScreen";
import { RiArrowGoBackFill } from "react-icons/ri";
import { IoArrowBackCircleSharp } from "react-icons/io5";
import { MdFullscreen } from "react-icons/md";

const LectureStudy = ({
  courseId,
  weekId,
  lectureId /*LectureTitle, LectureNo, LecDesc, LecVideo*/,
  isMobileLecOpen,
  setIsMobileLecOpen,
}) => {
  const [lecture, setLecture] = useState(null);

  const [lectureChangeLoading, setLectureChangeLoading] = useState(false);

  const { isMobile, setIsMobileNavbar, isMobileNavbar } =
    useMobileScreenContext();

  const toggleIsMobileLecOpen = () => {
    if (lectureId) {
      setIsMobileLecOpen(true);
    }
  };

  const getLecture = async () => {
    try {
      // console.log("fetching data", lectureId);
      const lectureRef = doc(
        db,
        "Courses",
        courseId,
        "weeks",
        weekId,
        "Lectures",
        lectureId
      );
      const querySnapshot = await getDoc(lectureRef);
      const lectureData = querySnapshot.data();
      setLecture(lectureData); // Update the lecture state
      console.log("Lecture datas:", lecture);
    } catch (err) {
      console.log("Error fetching lecture:", err);
    }
  };

  useEffect(() => {
    setLectureChangeLoading(true);
    getLecture(); // Fetch lecture details when component mounts
    console.log("****", lectureId);
    setLectureChangeLoading(false);
  }, [lectureId]); // Dependency array ensures re-fetching when IDs change

  return (
    <>
      {lecture && !lectureChangeLoading ? (
        <div
          className={`${isMobile ? "col-span-8" : "col-span-6"} ${
            isMobileLecOpen &&
            isMobile &&
            "fixed top-[66px] bottom-0 overflow-scroll mr-4 flex flex-col"
          } bg-slate-700 rounded-md p-4 flex flex-col`}
        >
          {isMobile && (
            <>
              <button onClick={() => setIsMobileLecOpen(!isMobileLecOpen)}>
                <div className="flex bg-slate-600 border-2 border-slate-500 p-2 mb-2 justify-center items-center gap-1 rounded-full px-4">
                  {isMobileLecOpen ? <><IoArrowBackCircleSharp className="text-2xl" /> <h1>Back to lectures</h1></> : <><MdFullscreen className="text-2xl" /> <h1>Maximize</h1></>}
                </div>
              </button>
            </>
          )}
          <h1 className="text-xl md:text-2xl font-medium text-slate-300">
            {lecture.LectureNo}. {lecture.LectureTitle}
          </h1>
          <div className="course-video mt-4 sticky md:relative top-0">
            <iframe
              className="w-full aspect-video"
              src={`${lecture.LecVideo}?autoplay=1`}
              frameborder="0"
              allowfullscreen="true"
              title="video"
            />
          </div>
          <h1 className="mt-2 text-2xl font-medium text-slate-300">
            Lecture Discrption
          </h1>
          <p className="mt-4">{lecture.LecDesc}</p>

          <h1 className="mt-4 text-2xl font-medium text-slate-300">
            Recommended Resourses
          </h1>
          <div className="text-slate-400 p-4">
            <li className="mb-3">
              <a
                href="https://www.freecodecamp.org/news/what-is-cybersecurity-forensics/"
                target="blank"
              >
                What is cyber scurity?
              </a>
            </li>
            <li className="mb-3">
              <a
                href="https://www.freecodecamp.org/news/how-to-install-kali-linux/"
                target="blank"
              >
                How to install kali linux?
              </a>
            </li>
            <li className="mb-3">
              <a
                href="https://www.hackthebox.com/hacker/hacking-labs"
                target="blank"
              >
                Virtaul Labs for Ethical Hackers
              </a>
            </li>
            <li className="mb-3">
              <a href="https://www.w3schools.com/" target="blank">
                W3schools
              </a>
            </li>
          </div>
        </div>
      ) : lectureChangeLoading ? (
        <div className={`flex justify-center items-center bg-slate-800 rounded-md w-full ${isMobile ? "col-span-8" : "col-span-6"} animate-pulse`}>
          <ThreeDots
            visible={true}
            width="200"
            color="orange"
            ariaLabel="infinity-spin-loading"
          />
        </div>
      ) : (
        <div className={`flex justify-center items-center bg-slate-800 rounded-md w-full ${isMobile ? "col-span-8" : "col-span-6"} animate-pulse`}>
          <ThreeDots
            visible={true}
            width="200"
            color="orange"
            ariaLabel="infinity-spin-loading"
          />
        </div>
      )}
    </>
  );
};

export default LectureStudy;
