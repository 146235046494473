import React from 'react'
import UnderDevelopment from '../UnderDevelopment'

const Assignments = () => {
  return (
    <div>
        <UnderDevelopment />
    </div>
  )
}

export default Assignments