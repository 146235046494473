import React from 'react'
import { Link, Navigate } from 'react-router-dom'
import { AiFillDashboard } from "react-icons/ai";
import { FaCrown } from "react-icons/fa";
import { MdAssignmentTurnedIn } from "react-icons/md";
import { MdExplore } from "react-icons/md";
import { BiSolidFactory } from "react-icons/bi";
import { CgLogOut } from "react-icons/cg";
import { useUserAuth } from '../../context/UserAuthContext';
import { options } from './sidebarOptions';
import Swal from 'sweetalert2';
import { useLoadingContext } from '../../context/LoadingContext';

const SmallMenu = () => {
    
const { logOut, user } = useUserAuth();

const { loading, setLoading, ShowLoading } = useLoadingContext();

const handleLogout = async () => {
    try {
      Swal.fire({
        title: "Are you sure?",
        text: "You won't be able to revert this!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#d33",
        cancelButtonColor: "#fd6713",
        confirmButtonText: "Yes, log out!",
      }).then(async (result) => {
        if (result.isConfirmed) {
          setLoading(true);
          await logOut();
          setLoading(false);
          Navigate("/login");
        }
      });
    } catch (error) {
      console.log(error.message);
      setLoading(false);
    }
  };


  return (
    
    <ul className="space-y-2 font-medium">

    {           
        options.map((option) => (
            <li>
                <Link to={option.menuLink} className="flex flex-col items-center p-2 text-gray-900 rounded-lg dark:text-white hover:bg-gray-100 dark:hover:bg-gray-700 group">
                    <div className='text-gray-400 hover:text-gray-100 flex justify-center items-center text-2xl'>
                        {option.menuIcon}
                    </div>
                </Link>
            </li>
        ))
        
    }

            <li>
                <Link onClick={handleLogout} className="flex items-center p-2 text-gray-900 rounded-lg dark:text-white hover:bg-gray-100 dark:hover:bg-gray-700 group">
                <div className='text-gray-400 hover:text-gray-100 flex justify-center items-center text-2xl'>
                    <CgLogOut />
                </div>
                </Link>
            </li>

    </ul>


  )
}

export default SmallMenu