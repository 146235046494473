import React, { useContext, useEffect, useState } from 'react';
import WeeksSidebar from '../../components/WeeksSidebar';
import { Params, useParams } from 'react-router-dom';
import Content_study from '../../components/Content_study';
import { collection, getDoc, getDocs } from 'firebase/firestore';
import LectureStudy from './LectureStudy';
import { db } from '../../firebase';
import { SidebarState } from '../Dashboard/Layout';
import { useMobileScreenContext } from '../../context/MobileScreen';

function CourseStudy() {

  // const [lecture, setLecture] = useState();
    
  const {ToggleButtonSidebar, isOpen, setIsOpen} = useContext(SidebarState);

  const { isMobile, setIsMobileNavbar, isMobileNavbar } =
  useMobileScreenContext();

  const [isMobileLecOpen, setIsMobileLecOpen] = useState(false);

  const { courseId, weekId, lectureId } = useParams();

  useEffect(() => {
    setIsOpen(false);
  }, [CourseStudy])

  // const getLecture = async () => {
  //   try {
  //     const lectureRef = collection(db, "Courses", courseId, "weeks", weekId, "Lectures");
  //     const querySnapshot = await getDocs(lectureRef);
  //     const lectureData = querySnapshot.docs.map(doc => ({id: doc.id, ...doc.data()}));
  //     setLecture(lectureData);
  //     console.log("****",lectureData);
  //   } catch (err) {
  //     console.log("Not found the lecture",err);
  //   }
  // }

  // useEffect(() => {
  //   getLecture();
  //   console.log("***fds Got the lectures", lecture)
  // },[courseId, weekId, lectureId])



  useEffect(() => {
    console.log("*******",courseId, weekId, lectureId)
  }, [courseId, weekId, lectureId])

  return (
    <>
    <div>
      <div className="grid grid-cols-8 gap-4 h-[calc(100vh-95px)]">
          <WeeksSidebar WeekcourseId={courseId} isMobileLecOpen={isMobileLecOpen} setIsMobileLecOpen={setIsMobileLecOpen} />
          {lectureId === undefined && <Content_study WeekcourseId={courseId} isMobileLecOpen={isMobileLecOpen} setIsMobileLecOpen={setIsMobileLecOpen} />}
          {lectureId !== undefined && <LectureStudy 
          courseId={courseId} 
          weekId={weekId} 
          lectureId={lectureId} 
          isMobileLecOpen={isMobileLecOpen} 
          setIsMobileLecOpen={setIsMobileLecOpen}
          // LectureNo={lecture ? lecture[0].LectureNo : ""}
          // LectureTitle={lecture ? lecture[0].LectureTitle : ""}
          // LecDesc={lecture ? lecture[0].LecDesc : ""}
          // LecVideo={lecture ? lecture[0].LecVideo : ""}
          />}
      </div>
    </div>
  </>
  )
}

export default CourseStudy