import logo from "../../assets/images/logo_wide.png"
import { CgLogOut } from "react-icons/cg";


const Header = () => {
  return (
    <>
    <div className="grid grid-cols-6 gap-4 h-auto mt-4">
        <div className="col-span-2 flex items-center">
            <img src={logo} alt="" className="h-12" />
        </div>
        <div className="col-span-2 flex justify-center items-center">
            <input type="text" name="" id="" className="w-full rounded-md h-10 outline-none border-2 border-slate-500 px-4 text-slate-100 bg-slate-700" placeholder="Search as Administrator..."/>
        </div>
        <div className="col-span-2 flex justify-end items-center cursor-pointer">
            <div className="button border-2 border-slate-500 hover:bg-slate-500 transition-all px-4 py-2 rounded-lg flex items-center gap-1">
                <CgLogOut className="text-[20px]" />
                <button>Logout</button>
            </div>
        </div>
    </div>
    </>
  )
}

export default Header