import React from 'react'

const AllEvents = () => {
  return (
    <>
    <h1 className='text-2xl font-semibold p-2 py-6'>Upcoming Events</h1>

    <div className="grid grid-cols-6 gap-4">
        <div className='md:col-span-3 col-span-6 bg-slate-800 rounded-xl hover:translate-y-[-2px] transition-all hover:shadow-2xl cursor-pointer'>
            <div className="image">
            <img src="https://i.imghippo.com/files/T0mgJ1716061887.png" className='rounded-t-xl' alt="" />
            </div>
            <div className='content p-4'>
              <h1 className='text-xl font-semibold line-clamp-2'>Lorem ipsum dolor sit amet consectetur adipisicing elit. Et dolorem dolores and the hero of the match </h1>
              <div className="time py-4">
                <p>Start: April 7, 2024 7:00PM</p>
                <p>End: April 7, 2024 8:00PM</p>
              </div>
              <div className="cta flex justify-between items-center">
                  <h1 className='text-green-500'>Online </h1>
                  <div className="button cursor-pointer text-slate-400 hover:text-slate-200">
                    Know more
                  </div>
                  <div className="button bg-slate-900 hover:bg-slate-950 cursor-pointer px-4 py-2 rounded-xl">
                    Register Now
                  </div>
              </div>
            </div>
        </div>
        <div className='md:col-span-3 col-span-6 bg-slate-800 rounded-xl hover:translate-y-[-2px] transition-all hover:shadow-2xl cursor-pointer'>
            <div className="image">
            <img src="https://img.youtube.com/vi/d8uYkTaX8y8/maxresdefault.jpg" className='rounded-t-xl' alt="" />
            </div>
            <div className='content p-4'>
              <h1 className='text-xl font-semibold line-clamp-2'>Lorem ipsum dolor sit amet consectetur adipisicing elit. Et dolorem dolores and the hero of the match </h1>
              <div className="time py-4">
                <p>Start: April 7, 2024 7:00PM</p>
                <p>End: April 7, 2024 8:00PM</p>
              </div>
              <div className="cta flex justify-between items-center">
                  <h1 className='text-green-500'>Online </h1>
                  <div className="button cursor-pointer text-slate-400 hover:text-slate-200">
                    Know more
                  </div>
                  <div className="button bg-slate-900 hover:bg-slate-950 cursor-pointer px-4 py-2 rounded-xl">
                    Register Now
                  </div>
              </div>
            </div>
        </div>
    </div> 
    <h1 className='text-2xl font-semibold p-2 py-6'>Past Events</h1>

    <div className="grid grid-cols-6 gap-4">
        <div className='col-span-3 bg-slate-800 rounded-2xl hover:translate-y-[-1px]'>
            <div className="image">
            <img src="https://i.imghippo.com/files/T0mgJ1716061887.png" className='rounded-t-xl' alt="" />
            </div>
            <div className='content p-4'>
            fsd
            </div>
        </div>
        <div className='col-span-3 bg-slate-800 rounded-2xl hover:translate-y-[-1px]'>
            <div className="image">
            <img src="https://i.imghippo.com/files/T0mgJ1716061887.png" className='rounded-t-xl' alt="" />
            </div>
            <div className='content p-4'>
            fsd
            </div>
        </div>
    </div> 
        
    </>
  )
}

export default AllEvents