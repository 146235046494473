import React, { useEffect, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import { db } from "../../firebase";
import {
  addDoc,
  collection,
  doc,
  getDoc,
  getDocs,
  onSnapshot,
  query,
  where,
} from "firebase/firestore";
import { BiDownload, BiSolidVideoRecording } from "react-icons/bi";
import { RxLapTimer } from "react-icons/rx";
import { FaDotCircle, FaEye } from "react-icons/fa";
import { ThreeDots } from "react-loader-spinner";
import { GiTick } from "react-icons/gi";
import { IoMdCheckmarkCircleOutline } from "react-icons/io";
import { useUserAuth } from "../../context/UserAuthContext";
import { useLoadingContext } from "../../context/LoadingContext";
import ConfettiExplosion from "react-confetti-explosion";

function CourseDetails() {
  const { courseId } = useParams();
  const [courseDetails, setCourseDetails] = useState();
  const navigate = useNavigate();
  const { user } = useUserAuth();
  const { loading, setLoading, ShowLoading } = useLoadingContext();
  const [isEnrolled, setIsEnrolled] = useState(false);
  const [poper, setPoper] = useState(false);

  const getCourseDetails = async () => {
    try {
      const docRef = doc(db, "Courses", courseId);
      const docSnap = await getDoc(docRef);
      setCourseDetails(docSnap.data());
      console.log(docSnap.data());
    } catch (err) {
      console.log("not done", err);
    }
  };

  useEffect(() => {
    getCourseDetails();
  }, []);

  useEffect(() => {
    checkIfEnrolled();
  });

  const checkIfEnrolled = async () => {
    try {
      const userDocRef = doc(db, "users", user.uid);
      const courseSubCollRef = collection(userDocRef, "courses");
      const q = query(courseSubCollRef, where("CID", "==", courseId));

      const querySnapshot = await getDocs(q);

      if (!querySnapshot.empty) {
        console.log("Already Enrolled");
        setIsEnrolled(true);
      } else {
        console.log("Not enrolled");
        setIsEnrolled(false);
      }
    } catch (error) {
      console.error("Error checking enrollment:", error);
    }
  };

  const calculateDiscount = (actual, current) => {
    const disPrice = actual - current;
    const discount = Math.floor((disPrice / actual) * 100);
    return discount;
  };

  const enrollAndPay = async (paytoCourseID) => {
    console.log("enroll and pay", paytoCourseID);
    return navigate(`./cta-pay/${courseId}`);
  };

  const enrollForFree = async (paytoCourseID) => {
    // Navigate(`/cta-pay/${courseId}`)
    // await checkIfEnrolled();
    try {
      setLoading(true);
      const userDocRef = doc(db, "users", user.uid);
      const courseSubColl = collection(userDocRef, "courses");

      const addCourse = addDoc(courseSubColl, {
        CID: paytoCourseID,
        title: courseDetails.CourseName,
        TID: null,
        access: true,
      });
      console.log(
        "User enrolled successfully!, added a subcollection and make the document"
      );
      setIsEnrolled(true);
      setLoading(false);
      setPoper(true);
    } catch (error) {
      console.error("Error enrolling user:", error);
      setIsEnrolled(false);
      setLoading(false);
    }
  };

  return (
    <>
      {poper ? (
        <ConfettiExplosion duration={3200} height={"1800px"} width={3000} />
      ) : null}

      {courseDetails ? (
        <>
          <div className="bg-slate-800 border-2 h-auto md:py-10 flex order-2 flex-col-reverse overflow-hidden flex-col md:flex-row items-center justify-center border-slate-700 rounded-2xl p-4 flex-wrap">
            <div className="md:w-4/6 w-full flex flex-col justify-center mt-4 md:mt-0">
              {/* <h1 className='px-4'>Unlock your true potential with Insiden</h1> */}
              <h1 className="text-2xl md:text-3xl text-left px-4 font-bold leading-[45px]">
                {courseDetails.CourseName}
              </h1>
              <div className="flex mt-6 md:gap-11 flex-wrap justify-between gap-2">
                <div className="flex items-center gap-2 text-sm md:text-xl text-green-400 px-4 ">
                  <BiSolidVideoRecording />
                  {courseDetails.features[1]}
                </div>
                <div className="flex items-center gap-2 text-sm md:text-xl text-orange-400 px-4">
                  <RxLapTimer />
                  {courseDetails.features[5]}
                </div>
                <div className="flex items-center gap-2 text-sm md:text-xl text-red-500 px-4">
                  <FaDotCircle />
                  {courseDetails.features[0]}
                </div>
              </div>
            </div>

            <div className="md:w-2/6 w-full flex items-center justify-center">
              <img
                src={courseDetails.CourseImage}
                className="rounded-2xl"
                alt=""
              />
            </div>
          </div>

          <div className="about grid grid-cols-12 py-2 mt-4">
            <div className="col-span-12 md:col-span-8 p-4 md:px-8">
              <h1 className="text-3xl">About Course</h1>
              <p className="mt-4">{courseDetails.SubTitle}</p>
              <p className="text-center my-4 text-red-500 animate-pulse">
                Scroll the course PDF
              </p>
              <div className="mt-4">
                <iframe
                  className="w-full aspect-video"
                  src={courseDetails.brochure}
                  width="100%"
                  allow="autoplay"
                ></iframe>
              </div>
              <a href={courseDetails.brochure} target="_blank">
                <div className="flex justify-center items-center mt-4 cursor-pointer">
                  <div className="rounded-2xl bg-orange-500 px-4 py-2">
                    <h1 className="text-xl flex items-center gap-2">
                      <BiDownload />
                      Download Broucher
                    </h1>
                  </div>
                </div>
              </a>
            </div>
            <div className="col-span-12 md:col-span-4 rounded-2xl">
              <div className="payment p-4 md:sticky md:top-20 bg-slate-800 rounded-2xl border-2 border-slate-700 px-8">
                <div className="price flex gap-4">
                  {courseDetails.CurrentPrice !== 0 ? (
                    <div className="text-green-400 text-3xl">
                      ₹{courseDetails.CurrentPrice}
                    </div>
                  ) : (
                    <div className="text-green-400 text-3xl">FREE</div>
                  )}

                  <div className="line-through text-slate-400 text-3xl">
                    ₹{courseDetails.ActualPrice}
                  </div>
                  <div className="font-bold text-slate-600 animate-pulse text-3xl">
                    {calculateDiscount(
                      courseDetails.ActualPrice,
                      courseDetails.CurrentPrice
                    )}
                    % Off
                  </div>
                </div>
                <div className="course-perks my-3">
                  <h1 className="text-xl font-semibold">
                    This Course Includes:
                  </h1>
                  {courseDetails.includesPay.map((include) => (
                    <div className="flex items-center gap-2 my-2 text-[18px]">
                      <IoMdCheckmarkCircleOutline /> <h1>{include}</h1>
                    </div>
                  ))}
                </div>
                {isEnrolled ? (
                  <Link to={`../course-study/${courseId}`}>
                    <div className="buynow flex items-center justify-center p-4 my-4 rounded-xl cursor-pointer bg-orange-600 hover:bg-orange-700">
                      <h1 className="font-semibold text-xl">Enrolled, View</h1>
                    </div>
                  </Link>
                ) : courseDetails.CurrentPrice == 0 ? (
                  <div
                    className="buynow flex items-center justify-center p-4 my-4 rounded-xl cursor-pointer bg-green-600 hover:bg-green-700"
                    onClick={() => enrollForFree(courseId)}
                  >
                    <h1 className="font-semibold text-xl">Enroll for FREE</h1>
                  </div>
                ) : (
                  <div
                    className="buynow flex items-center justify-center p-4 my-4 rounded-xl cursor-pointer bg-green-600 hover:bg-green-700"
                    onClick={() => enrollAndPay(courseId)}
                  >
                    <h1 className="font-semibold text-xl">Enroll Now</h1>
                  </div>
                )}
              </div>
            </div>
          </div>
        </>
      ) : (
        <div className="bg-slate-800 border-2 flex mx-4 items-center justify-center border-slate-700 rounded-2xl p-4 flex-wrap h-[600px] animate-pulse">
          <div className="flex justify-center items-center bg-slate-800 rounded-md w-full col-span-6 animate-pulse">
            <ThreeDots
              visible={true}
              width="200"
              color="orange"
              ariaLabel="infinity-spin-loading"
            />
          </div>
        </div>
      )}
    </>
  );
}

export default CourseDetails;
