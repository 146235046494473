import React from 'react'
import Message from './Message'
import { IoPerson } from 'react-icons/io5'

const UserInfo = ({user}) => {
  return (
    <div className='bg-[#1a2d48d9] mt-4 rounded-xl p-4 md:sticky md:top-[74px]'>
    <div className="header">
        <h1 className='text-2xl font-bold'>Hi, {user.displayName? user.displayName : "Loading..."} 👋</h1>
        <hr className='mt-3 opacity-10' />
    </div>
    <div className="profile pic my-4 flex justify-center items-center">
            <img src={user.photoURL} alt="" className='picture h-[150px] w-[150px] bg-slate-600 rounded-full border-2 border-slate-500 flex justify-center items-center' />
    </div>
    <div className="details text-slate-400">
        <p>Email- <span className='font-medium'>{user.email? user.email : "Loading..."}</span></p>
        <p >WhatsApp- <span className='font-medium'>{user.whatsAppNo? user.whatsAppNo : "Not Applicable" }</span></p>
        <hr className='my-4 opacity-30' />
        <h1 className='text-slate-200 text-[17px]'>Your Growth Stats</h1>
        <div className="grid grid-cols-6 gap-4 my-4">
            <div className="col-span-2 border-2 border-slate-500 rounded-md p-2">
                <p className='text-center text-red-400 font-bold text-2xl'>0</p>
                <p className='text-center text-sm text-slate-300'>Total Connections</p>
            </div>
            <div className="col-span-2 border-2 border-slate-500 rounded-md p-2">
                <p className='text-center text-green-400 font-bold text-2xl'>1+</p>
                <p className='text-center text-sm text-slate-300'>Project Completed</p>
            </div>
            <div className="col-span-2 border-2 border-slate-500 rounded-md p-2">
                <p className='text-center text-green-400 font-bold text-2xl'>2+</p>
                <p className='text-center text-sm text-slate-300'>Idea Shared</p>
            </div>
        </div>
        <h1 className='text-slate-200 text-[17px]'>Your Learning Progress</h1>
        <div className="grid grid-cols-6 gap-4 my-4">
            <div className="col-span-2 border-2 border-slate-500 rounded-md p-2">
                <p className='text-center text-red-400 font-bold text-2xl'>13+</p>
                <p className='text-center text-sm text-slate-300'>Pending Assignment</p>
            </div>
            <div className="col-span-2 border-2 border-slate-500 rounded-md p-2">
                <p className='text-center text-green-400 font-bold text-2xl'>15+</p>
                <p className='text-center text-sm text-slate-300'>Lectures Completed</p>
            </div>
            <div className="col-span-2 border-2 border-slate-500 rounded-md p-2">
                <p className='text-center text-green-400 font-bold text-2xl'>2+</p>
                <p className='text-center text-sm text-slate-300'>Lecture Watch / Day</p>
            </div>
        </div>
    </div>
</div>
  )
}

export default UserInfo