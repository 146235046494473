import { createContext, useContext, useEffect, useState } from "react";

const MobileScreen = createContext();

export function MobileContextProvider({children}) {
    const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);
    const [isMobileNavbar, setIsMobileNavbar] = useState(false);

    const toggleIsMobileNavbar = () => {
        setIsMobileNavbar(!isMobileNavbar);
        console.log("fdsfdfsf", isMobileNavbar);
    }

    const updateMobileStatus = () => {
        setIsMobile(window.innerWidth <= 768);
    };

    useEffect(() => {
        // Set initial value
        updateMobileStatus();

        // Add event listener
        window.addEventListener('resize', updateMobileStatus);

        // Cleanup the event listener on unmount
        return () => window.removeEventListener('resize', updateMobileStatus);
    }, []);

    return(
        <MobileScreen.Provider
            value={{isMobile, setIsMobileNavbar, isMobileNavbar, toggleIsMobileNavbar}} >           
            {children} 
        </MobileScreen.Provider>
    )
}

export function useMobileScreenContext() {
    return useContext(MobileScreen);
}


