import React from 'react'
import { Link } from 'react-router-dom'

const PageNotFound = () => {
  return (
    <div class="min-h-screen flex flex-grow items-center justify-center bg-slate-800">
        <div class="rounded-lg bg-slate-900 p-8 text-center shadow-xl">
            <h1 class="mb-4 text-4xl font-bold">Oops! 404</h1>
            <p class="text-gray-400">Oops! The page you are looking for could not be found.</p>
            <Link to="/" class="mt-4 inline-block rounded bg-orange-500 px-4 py-2 font-semibold text-white hover:bg-orange-600"> Go back to Home</Link>
        </div>
    </div>
  )
}

export default PageNotFound;