import React from 'react'
import Header from './components/Header'
import Hero from './components/Hero'
import { motion } from "framer-motion"

const Landing = () => {

  const bodyComponent = {
    hidden: {
      opacity:0,
      y: '2vh',
    },
    visible: {
      opacity:1,
      y:0,
    }
  }

  return (
    <>
    <motion.div className='relative overflow-hidden' 
      variants={bodyComponent}
      initial="hidden"
      animate="visible"
    >
        <Header />
        <Hero />
    </motion.div>
    </>
  )
}

export default Landing