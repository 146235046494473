import React from 'react'

const Message = () => {
  return (
    <div className="messages bg-slate-600 rounded-xl p-4 border-2 border-slate-500 my-4">
        <p>24 April, 2024</p>
        <div className="message-heading my-4">
            <h1>LIVE Orientation Session for 1-Month FREE Cohort</h1>
        </div>
        <div className="message-regarding my-4">
            <h1>Fundamentals of Ethical Hacking and exploring the Cyber Security</h1>
        </div>
        <div className="message-content">
            <h1>Welcome to our Ethical Hacking Cohort! We're excited to kick off this journey with you.
            The wait for the cohort is over, Today is Live Orientation Session for 1-Month Free Cohort on Fundamentals of Ethical Hacking and exploring the cyber security.
</h1>

            <p className='text-orange-300 my-4'>
                <a href="https://meet.google.com/zox-vkjs-pnk">https://meet.google.com/run-iukw-wkb</a>
            </p>
        </div>
    </div>
  )
}

export default Message