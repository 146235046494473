import React from "react";
import Logo from "../../../assets/images/logo_wide.png";
import SmallLogo from "../../../assets/images/logo.png";
import { IoArrowForwardSharp } from "react-icons/io5";
import { Link } from "react-router-dom";
import { motion, transform } from "framer-motion";
import { duration } from "@mui/material";

const Header = () => {

  const headerComponent = {
    hidden: {
      opacity:0,
    },
    visible: {
      opacity:1,
      transition: {
        type: 'spring',
        delay: 5
      }
    }
  }

  return (
    <motion.div className="md:h-20 h-16 w-full bg-gray-400 bg-clip-padding backdrop-filter backdrop-blur-sm bg-opacity-10 border-b-[1px] border-gray-700 flex justify-between items-center p-4 md:px-28 sticky top-0">
      <div className="logo relative" 
      variants={headerComponent} 
      initial="hidden" 
      animate="visible"
      >
        {/* <img src={Logo} alt="" className="h-9" /> */}
        <div className="flex items-center justify-center gap-1">
            <img src={SmallLogo} alt="" className="h-9" />
            <h1 className="font-semibold text-3xl">Insiden</h1>
        </div>
      </div>
      <div className="options z-50 hidden md:block">
        <ul className="menu menu-vertical lg:menu-horizontal bg-base-200 rounded-box">
          <li>
            <a>Community</a>
          </li>
          <li>
            <a>Events</a>
          </li>
          <li>
            <a>Courses</a>
          </li>
          <li>
            <a>Products</a>
          </li>
          <li>
            <a>Services</a>
          </li>
        </ul>
      </div>
      <div className="auth">
        <Link to={"/login"}>
        <button className="px-6 bg-slate-600 py-2 rounded-full border-2 border-slate-500 flex items-center justify-center gap-2 hover:scale-105">
          Get Started <IoArrowForwardSharp />
        </button>
        </Link>
      </div>
    </motion.div>
  );
};

export default Header;
