import React, { useEffect, useState } from 'react';
import hackingCourse from "../../assets/images/ethicalhacking.png"
import { FaEye } from 'react-icons/fa';
import { collection, getDocs } from 'firebase/firestore';
import { db } from '../../firebase';
import { InfinitySpin } from 'react-loader-spinner';
import { Link } from 'react-router-dom';
import { useUserAuth } from '../../context/UserAuthContext';

const AllCourses = () => {

    const [courses, setCourses] = useState();
    const {user} =  useUserAuth();

    
    const getCourses = async () => {
        try {
            const querySnapshot = await getDocs(collection(db, "Courses"));
            const coursesData = querySnapshot.docs.map(doc => ({id: doc.id, ...doc.data()}));
            setCourses(coursesData);
            console.log(coursesData)
        } catch(err) {
            console.log("not done", err);
        }
    }
    
    useEffect(() => {
        getCourses();
    }, []);

    const calculateDiscount = (actual, current) => {
        const disPrice = actual - current;
        const discount = Math.floor(disPrice/actual*100);
        return discount;
    }


  return (
    <>
    <h1 className='text-3xl mt-2 font-bold'>What would you like to <span className='text-orange-500'>learn</span>?</h1>

    <div className="coursesmallcards mt-4 flex gap-4 flex-wrap py-2">
        <div className='bg-slate-600 px-4 py-1 rounded-md text-slate-300 hover:bg-slate-700 hover:text-white transition-all duration-[0.1s] cursor-pointer'>Frontend Web Development</div>
        <div className='bg-slate-600 px-4 py-1 rounded-md text-slate-300 hover:bg-slate-700 hover:text-white transition-all duration-[0.1s] cursor-pointer'>Fundamentals of Ethical Hacking</div>
    </div>
    <div className="courseCard mt-4 grid grid-cols-1 md:grid-cols-3 gap-4">

    {courses? courses.map((course) => (
        <Link to={`../coursedetails/${course.id}`}>
            <div className="onecourse bg-slate-800 p-4 rounded-2xl border-2 border-slate-700  hover:cursor-pointer hover:translate-y-[-2px] transition-all duration-[0.15s] hover:shadow-2xl">
                <div className="course-img">
                    <img src={course.CourseImage} alt="" className='rounded-2xl'/>
                </div>
                <h1 className='font-bold text-[20px] my-2 line-clamp-2'>{course.CourseName}</h1>
                <div className='pricing flex gap-4 mt-4 text-xl'>    
                    <p className='font-bold text-orange-400'>₹{course.CurrentPrice}</p>
                    <p className='font-bold line-through '>₹{course.ActualPrice}</p>
                    <p className='font-bold text-slate-500'>{calculateDiscount(course.ActualPrice, course.CurrentPrice)}% Off</p>
                </div>
            </div>
        </Link>

    )) : 
    <>
    <div className="onecourse bg-slate-800 p-4 rounded-2xl border-2 border-slate-700  hover:cursor-pointer hover:translate-y-[-2px] transition-all duration-[0.15s] hover:shadow-2xl">
        <div className="course-img w-full h-40 bg-slate-600 animate-pulse rounded-xl">
            
        </div>
        <div className='font-semibold text-[25px] my-2 w-full h-10'></div>
        <div className='pricing flex gap-4 mt-4 text-xl w-full h-8 bg-slate-600 rounded-xl animate-pulse'>    
        </div>
    </div>
    <div className="onecourse bg-slate-800 p-4 rounded-2xl border-2 border-slate-700  hover:cursor-pointer hover:translate-y-[-2px] transition-all duration-[0.15s] hover:shadow-2xl">
        <div className="course-img w-full h-40 bg-slate-600 animate-pulse rounded-xl">
            
        </div>
        <div className='font-semibold text-[25px] my-2 w-full h-10'></div>
        <div className='pricing flex gap-4 mt-4 text-xl w-full h-8 bg-slate-600 rounded-xl animate-pulse'>    
        </div>
    </div>
    <div className="onecourse bg-slate-800 p-4 rounded-2xl border-2 border-slate-700  hover:cursor-pointer hover:translate-y-[-2px] transition-all duration-[0.15s] hover:shadow-2xl">
        <div className="course-img w-full h-40 bg-slate-600 animate-pulse rounded-xl">
            
        </div>
        <div className='font-semibold text-[25px] my-2 w-full h-10'></div>
        <div className='pricing flex gap-4 mt-4 text-xl w-full h-8 bg-slate-600 rounded-xl animate-pulse'>    
        </div>
    </div>
    </>
    }

    </div>
    </>
  )
}

export default AllCourses