import logo from './logo.svg';
import './App.css';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import Dashboard from './pages/Dashboard/Dashboard';
import Login from './pages/Login/Login';
import Courses from './pages/courses/Courses';
import Layout from './pages/Dashboard/Layout';
import CourseDetails from './pages/courses/CourseDetails'
import CourseStudy from './pages/courses/CourseStudy';
import { UserAuthContextProvider } from './context/UserAuthContext';
import ProtectedRoute from "./components/ProtectedRoute";
import PageNotFound from './pages/PageNotFound';
import Admin from './pages/Admin/Admin';
import AdminDashboard from './pages/Admin/AdminDashboard';
import AdminManageCourses from './pages/Admin/AdminManageCourses';
import AdminManageEnrollment from './pages/Admin/AdminManageEnrollment';
import AdminSendAnnouncement from './pages/Admin/AdminSendAnnouncement';
import Signup from './pages/Signup/Signup';
import CourseEnroll from './pages/Signup/CourseEnroll';
import { LoadingContextProvider } from './context/LoadingContext';
import AllCourses from './pages/AllCourses/AllCourses';
import Events from './pages/Events/Events';
import Assignments from './pages/Assignments/Assignments';
import { MobileContextProvider } from './context/MobileScreen';
import Work from './components/Work/Work';
import Landing from './pages/LandingPage/Landing';

function App() {
  return (
  <BrowserRouter>
  
  <MobileContextProvider>
  <LoadingContextProvider>
    <UserAuthContextProvider>
      <Routes>
        <Route path='*' element={<PageNotFound/>} />
        
        <Route path='/' element={<Landing />}>
          <Route />
        </Route>

        <Route path='portal' element={<ProtectedRoute><Layout /></ProtectedRoute>}>
          <Route path='' element={<ProtectedRoute><Dashboard /></ProtectedRoute>} />
          <Route path='courses' element={<ProtectedRoute><Courses /></ProtectedRoute>} />
          <Route path='events' element={<ProtectedRoute><Events /></ProtectedRoute>} />
          <Route path='assignments' element={<ProtectedRoute><Assignments /></ProtectedRoute>} />
          <Route path='coursedetails/:courseId' element={<ProtectedRoute><CourseDetails /></ProtectedRoute>} />
          <Route path='allcourses' element={<ProtectedRoute><AllCourses /></ProtectedRoute>} />
          <Route path='course-study/:courseId' element={<ProtectedRoute><CourseStudy/></ProtectedRoute>} />
          <Route path='course-study/:courseId/:weekId' element={<ProtectedRoute><CourseStudy /></ProtectedRoute>} />
          <Route path='course-study/:courseId/:weekId/:lectureId' element={<ProtectedRoute><CourseStudy /></ProtectedRoute>}/>
          <Route path='works' element={<ProtectedRoute><Work/></ProtectedRoute>} />           
        </Route>
        <Route path='login' element={<Login />}/>
        <Route path='signup' element={<Signup />}/>
        <Route path='admin' element={<Admin />} >
          <Route path='dashboard' element={<AdminDashboard />} />
          <Route path='managecourses' element={<AdminManageCourses />} />
          <Route path='manageenrollment' element={<AdminManageEnrollment />} />
          <Route path='sendannouncement' element={<AdminSendAnnouncement />} />
        </Route>
        <Route path='CTA-pay/:courseId' element={<CourseEnroll />}/>
      </Routes>
      </UserAuthContextProvider>
    </LoadingContextProvider>
  </MobileContextProvider> 
  </BrowserRouter>
  );
}

export default App;
