import { useEffect, useState } from "react";
import { IoIosArrowDown, IoIosArrowUp } from "react-icons/io";
import { IoRadioButtonOffSharp } from "react-icons/io5";
import WeekLecture from "./WeekLecture";
import { Link } from "react-router-dom";
import { collection, getDoc, getDocs, orderBy, query } from "firebase/firestore";
import { db } from "../firebase";
import { FallingLines } from "react-loader-spinner";


const Week = ({weekId, WeekcourseId, onState, theWeekStatefromChild, isMobileLecOpen, setIsMobileLecOpen}) => {

    useEffect(() => {
      isWeekOpen(theWeekStatefromChild[weekId]);
      console.log(theWeekStatefromChild);
    }, [theWeekStatefromChild])

    const [Lectures, setLecutres] = useState();
    const [weekOpen, isWeekOpen] = useState(false); 

    const handleWeek = () => {
        onState(weekId, !theWeekStatefromChild[weekId]);
        // isWeekOpen(weekIsOpen[weekId]);
    }

    const getLectures = async () => {
      try {

      const queryLec = query(collection(db, "Courses", WeekcourseId, "weeks", weekId, "Lectures"), orderBy("LectureNo", "asc"));
      const querySnapshot = await getDocs(queryLec);

      const lecturesData = querySnapshot.docs.map(doc => ({id: doc.id, ...doc.data()}));      
      setLecutres(lecturesData);
      
      console.log(lecturesData);

      } catch(err) {
          console.log("not done", err);
      }
  }
  
  useEffect(() => {
      getLectures();
  }, []);
  
  // console.log(weekIsOpen[weekId]);
  

  return (
    <>
    <div className={`h-26 mt-4 p-2 ${weekOpen? "bg-slate-700" : " "} rounded-md cursor-pointer text-slate-400 border-b-gray-600 border-b-2 transition-all flex justify-between items-center`} onClick={handleWeek}>
        <p className={`${weekOpen ? "text-white" : ""}`}>
          {weekId}
        </p>
        <p>
          {weekOpen? <IoIosArrowUp /> : <IoIosArrowDown />}
        </p>
    </div>
    {weekOpen ? 
    <>

    {/* <WeekLecture weekId={weekId}/>
    <WeekLecture />
    <WeekLecture />
    <WeekLecture />
    <WeekLecture /> */}

    {Lectures ? Lectures.map((Lec) => <WeekLecture setIsMobileLecOpen={setIsMobileLecOpen} isMobileLecOpen={isMobileLecOpen} WeekcourseId={WeekcourseId} weekId={weekId} LectureNo={Lec.LectureNo} LectureTitle={Lec.LectureTitle} LecId={Lec.id} LecDesc={Lec.LecDesc} LecVideo={Lec.LecVideo} />) 
    
    : 
    <div className="flex justify-center items-center text-center">
    <FallingLines
    color="orange"
    width="100"
    visible={true}
    ariaLabel="falling-circles-loading"
    />
    </div>
    }

    </>        
    : <></>}
  </>
  )
}

export default Week