import React from 'react'
import { GiMeepleGroup, GiReceiveMoney } from "react-icons/gi";
import { FaBook, FaBookOpen, FaVideo } from "react-icons/fa";
import { MdGroupWork, MdManageAccounts, MdPerson } from "react-icons/md";
import { CgBookmark, CgEye, CgOrganisation } from 'react-icons/cg';
import { Link } from 'react-router-dom';

const AdminDashboard = () => {
  return (
    <>
    <div className="grid grid-cols-12 gap-6">
        <div className="col-span-3 bg-slate-600 mt-6 rounded-xl p-2 flex justify-center flex-col gap-2 hover:shadow-[0_5px_52px_-20px_rgba(0,0,0,0.3)] transition-all duration-200 backdrop-blur-lg hover:shadow-orange-400 hover:scale-[1.02]">
            <div className='flex justify-center'>
                <h1 className='text-6xl mt-4'><GiReceiveMoney /></h1>
            </div>
            <div className='flex justify-center'>
                <h1 className='text-4xl font-bold text-green-400'>₹ 32,000+</h1>
            </div>
            <span className='text-center text-2xl'>
                Total Earning
            </span>
            <div className='flex justify-center bg-slate-700 rounded-md mt-2 p-2 hover:bg-slate-800 cursor-pointer transition-all'>
                View All
            </div>
        </div>
        <div className="col-span-3 bg-slate-600 mt-6 rounded-xl p-2 flex justify-center flex-col gap-2 hover:shadow-[0_5px_52px_-20px_rgba(0,0,0,0.3)] transition-all duration-200 backdrop-blur-2xl hover:shadow-orange-400 hover:scale-[1.02]">
            <div className='flex justify-center'>
                <h1 className='text-6xl mt-4'><FaBookOpen /></h1>
            </div>
            <div className='flex justify-center'>
                <h1 className='text-4xl font-bold text-green-400'>1+</h1>
            </div>
            <span className='text-center text-2xl'>
                Total Courses
            </span>
            <div className='flex justify-center bg-slate-700 rounded-md mt-2 p-2 hover:bg-slate-800 cursor-pointer transition-all'>
                View All
            </div>
        </div>
        <div className="col-span-3 bg-slate-600 mt-6 rounded-xl p-2 flex justify-center flex-col gap-2 hover:shadow-[0_5px_52px_-20px_rgba(0,0,0,0.3)] transition-all duration-200 backdrop-blur-2xl hover:shadow-orange-400 hover:scale-[1.02]">
            <div className='flex justify-center'>
                <h1 className='text-6xl mt-4'><CgOrganisation /></h1>
            </div>
            <div className='flex justify-center'>
                <h1 className='text-4xl font-bold text-green-400'>100+</h1>
            </div>
            <span className='text-center text-2xl'>
                Total Enrollments
            </span>
            <div className='flex justify-center bg-slate-700 rounded-md mt-2 p-2 hover:bg-slate-800 cursor-pointer transition-all'>
                View All
            </div>
        </div>
        <div className="col-span-3 bg-slate-600 mt-6 rounded-xl p-2 flex justify-center flex-col gap-2 hover:shadow-[0_5px_52px_-20px_rgba(0,0,0,0.3)] transition-all duration-200 backdrop-blur-2xl hover:shadow-orange-400 hover:scale-[1.02]">
            <div className='flex justify-center'>
                <h1 className='text-6xl mt-4'><FaVideo /></h1>
            </div>
            <div className='flex justify-center'>
                <h1 className='text-4xl font-bold text-green-400'>60+</h1>
            </div>
            <span className='text-center text-2xl'>
                Total Lectures Recorded
            </span>
            <div className='flex justify-center bg-slate-700 rounded-md mt-2 p-2 hover:bg-slate-800 cursor-pointer transition-all'>
                View All
            </div>
        </div>
    </div>

    <div className="transion mt-6">
        <div className='flex justify-between items-center'>
            <h1 className='text-2xl'>Latest Transaction</h1>
            <div>
                <Link to={"/admin/manageenrollment"}>
                <h1 className='text-slate-400 pr-3 cursor-pointer flex items-center gap-2 hover:text-orange-400 transition-all'><CgEye/>View all</h1>
                </Link>
            </div>
        </div>
        

        <div className="relative overflow-x-auto shadow-md sm:rounded-lg mt-6">
            <table className="w-full text-sm text-left rtl:text-right text-gray-500 dark:text-gray-400">
                <thead className="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
                    <tr>
                        <th scope="col" className="px-6 py-3">
                            Course name
                        </th>
                        <th scope="col" className="px-6 py-3">
                            User Name
                        </th>
                        <th scope="col" className="px-6 py-3">
                            Course ID
                        </th>
                        <th scope="col" className="px-6 py-3">
                            Transaction ID
                        </th>
                        <th scope="col" className="px-6 py-3">
                            Acceess
                        </th>
                    </tr>
                </thead>
                <tbody>
                    <tr className="bg-white border-b dark:bg-gray-800 dark:border-gray-700 hover:bg-gray-50 dark:hover:bg-gray-600">
                        <th scope="row" className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white">
                            Apple MacBook Pro 17"
                        </th>
                        <td className="px-6 py-4">
                            Silver
                        </td>
                        <td className="px-6 py-4">
                            Laptop
                        </td>
                        <td className="px-6 py-4">
                            $2999
                        </td>
                        <td className="px-6 py-4">
                            <a href="#" className="font-medium text-blue-600 dark:text-blue-500 hover:underline">false</a>
                        </td>
                    </tr>
                    <tr className="bg-white border-b dark:bg-gray-800 dark:border-gray-700 hover:bg-gray-50 dark:hover:bg-gray-600">
                        <th scope="row" className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white">
                            Apple MacBook Pro 17"
                        </th>
                        <td className="px-6 py-4">
                            Silver
                        </td>
                        <td className="px-6 py-4">
                            Laptop
                        </td>
                        <td className="px-6 py-4">
                            $2999
                        </td>
                        <td className="px-6 py-4">
                            <a href="#" className="font-medium text-blue-600 dark:text-blue-500 hover:underline">false</a>
                        </td>
                    </tr>
                    <tr className="bg-white border-b dark:bg-gray-800 dark:border-gray-700 hover:bg-gray-50 dark:hover:bg-gray-600">
                        <th scope="row" className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white">
                            Apple MacBook Pro 17"
                        </th>
                        <td className="px-6 py-4">
                            Silver
                        </td>
                        <td className="px-6 py-4">
                            Laptop
                        </td>
                        <td className="px-6 py-4">
                            $2999
                        </td>
                        <td className="px-6 py-4">
                            <a href="#" className="font-medium text-blue-600 dark:text-blue-500 hover:underline">false</a>
                        </td>
                    </tr>
                    <tr className="bg-white border-b dark:bg-gray-800 dark:border-gray-700 hover:bg-gray-50 dark:hover:bg-gray-600">
                        <th scope="row" className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white">
                            Apple MacBook Pro 17"
                        </th>
                        <td className="px-6 py-4">
                            Silver
                        </td>
                        <td className="px-6 py-4">
                            Laptop
                        </td>
                        <td className="px-6 py-4">
                            $2999
                        </td>
                        <td className="px-6 py-4">
                            <a href="#" className="font-medium text-blue-600 dark:text-blue-500 hover:underline">false</a>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>
    </>
  )
}

export default AdminDashboard