import React, { useEffect, useState } from "react";
import Sidebar from "../../components/Sidebar/Sidebar";
import Header from "../../components/Header/Header";
import { Link, Outlet } from "react-router-dom";
import logo from "../../assets/images/logo_wide.png";
import slogo from "../../assets/images/slogo.png";
import { LatestUpdate } from "../../components/Dashboard/LatestUpdate";
import UserInfo from "../../components/Dashboard/UserInfo";
import { useUserAuth } from "../../context/UserAuthContext";
import { collection, getDocs } from "firebase/firestore";
import { db } from "../../firebase";
import { FallingLines, InfinitySpin } from "react-loader-spinner";
import { FaCrown, FaEye } from "react-icons/fa";
import { IoBookSharp } from "react-icons/io5";
import { TbTimelineEventFilled } from "react-icons/tb";
import { IoIosArrowForward, IoIosPeople } from "react-icons/io";
import { Swiper, SwiperSlide, useSwiper } from "swiper/react";
import "swiper/css";
import eventImg from "../../assets/images/course-img.jpg";
import { BiArrowToRight, BiCalendarMinus, BiTimer } from "react-icons/bi";
import { MdNavigateNext } from "react-icons/md";
import { Autoplay, Pagination, Navigation, EffectCards } from "swiper/modules";
import "swiper/css/navigation";
import "swiper/css/pagination";
import { useMobileScreenContext } from "../../context/MobileScreen";

function Dashboard() {
  const { user } = useUserAuth();

  const { isMobile, setIsMobileNavbar, isMobileNavbar } =
    useMobileScreenContext();

  const [noofcourses, setNoOfCourse] = useState(0);
  const [availableCourses, setAvailabeCourses] = useState(0);
  const [cardLoading, setCardLoading] = useState(true);
  const swiper = useSwiper();

  const getCourses = async () => {
    console.log("fetcheing courses", user);
    try {
      setCardLoading(true);
      const availableCourses = await getDocs(collection(db, "Courses"));
      const availableC = availableCourses.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));

      const querySnapshot = await getDocs(
        collection(db, "users", user.uid, "courses")
      );
      const coursesData = querySnapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));

      const numberofcourses = coursesData.length;
      const NoAvailableCourses = availableC.length;
      setNoOfCourse(numberofcourses);
      setAvailabeCourses(NoAvailableCourses);
      setCardLoading(false);
    } catch (err) {
      console.error("Error fetching courses:", err);
    }
  };

  useEffect(() => {
    getCourses();
    console.log(isMobile, "is mobile");
  }, []);

  useEffect(() => {
    console.log(noofcourses);
    console.log(availableCourses);
  }, [noofcourses, availableCourses]);

  return (
    <>
      {isMobile && !cardLoading ? (
        <div className="slider">
          <Swiper
            spaceBetween={30}
            centeredSlides={true}
            autoplay={{
              delay: 2500,
              disableOnInteraction: false,
            }}
            navigation={false}
            modules={[Autoplay, Pagination, Navigation]}
            className="mySwiper"
          >
            <SwiperSlide>
              <div className="card transition-all duration-[0.2s] bg-gradient-to-r from-slate-900 to-slate-700 border-2 border-slate-600 flex flex-col justify-between rounded-xl p-2 gap-4 cursor-pointer">
                <div className="flex justify-between">
                  <div className="flex flex-col content">
                    <div className="text-5xl font-semibold">{noofcourses}</div>
                    <div className="text-2xl text-slate-300">
                      Enrolled Courses
                    </div>
                  </div>
                  <div className="logo bg-red-900/35 w-20 h-20 flex justify-center items-center p-3 rounded-full">
                    <FaCrown className="text-6xl opacity-45" />
                  </div>
                </div>
                <Link to={"./courses"} className="w-full">
                  <div className="button border-2 border-slate-700 flex items-center justify-center p-2 rounded-xl cursor-pointer">
                    View All
                  </div>
                </Link>
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div className="card transition-all duration-[0.2s] bg-gradient-to-r from-slate-900 to-slate-700 border-2 border-slate-600 flex flex-col justify-between rounded-xl p-2 gap-4 cursor-pointer">
                <div className="flex justify-between">
                  <div className="flex flex-col content">
                    <div className="text-5xl font-semibold">
                      {availableCourses}
                    </div>
                    <div className="text-2xl text-slate-300">
                      Available Courses
                    </div>
                  </div>
                  <div className="logo bg-red-900/35 w-20 h-20 flex justify-center items-center p-3 rounded-full">
                    <IoBookSharp className="text-6xl opacity-45" />
                  </div>
                </div>
                <Link to={"./allcourses"} className="w-full">
                  <div className="button border-2 border-slate-700 flex items-center justify-center p-2 rounded-xl cursor-pointer">
                    View All
                  </div>
                </Link>
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div className="card transition-all duration-[0.2s] bg-gradient-to-r from-slate-900 to-slate-700 border-2 border-slate-600 flex flex-col justify-between rounded-xl p-2 gap-4 cursor-pointer">
                <div className="flex justify-between">
                  <div className="flex flex-col content">
                    <div className="text-5xl font-semibold">10+</div>
                    <div className="text-2xl text-slate-300">Events</div>
                  </div>
                  <div className="logo bg-red-900/35 w-20 h-20 flex justify-center items-center p-3 rounded-full">
                    <TbTimelineEventFilled className="text-6xl opacity-45" />
                  </div>
                </div>
                <Link to={"./events"} className="w-full">
                  <div className="button border-2 border-slate-700 flex items-center justify-center p-2 rounded-xl cursor-pointer">
                    View All
                  </div>
                </Link>
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div className="card transition-all duration-[0.2s] bg-gradient-to-r from-slate-900 to-slate-700 border-2 border-slate-600 flex flex-col justify-between rounded-xl p-2 gap-4 cursor-pointer">
                <div className="flex justify-between">
                  <div className="flex flex-col content">
                    <div className="text-5xl font-semibold">1500+</div>
                    <div className="text-2xl text-slate-300">Members</div>
                  </div>
                  <div className="logo bg-red-900/35 w-20 h-20 flex justify-center items-center p-3 rounded-full">
                    <IoIosPeople className="text-6xl opacity-45" />
                  </div>
                </div>
                <Link to={"./courses"} className="w-full">
                  <div className="button border-2 border-slate-700 flex items-center justify-center p-2 rounded-xl cursor-pointer">
                    Join on WhatsApp
                  </div>
                </Link>
              </div>
            </SwiperSlide>
          </Swiper>
        </div>
      ) : isMobile ? (
        <div className="bg-[#1a2d48d9] rounded-xl h-[150px] animate-pulse p-4 flex flex-col justify-center items-center"></div>
      ) : null}

      {!cardLoading && !isMobile ? (
        <div class="grid md:grid-cols-4 grid-cols-1 lg:grid-cols-4 gap-4">
          <div className="card transition-all duration-[0.2s] bg-gradient-to-r from-slate-900 to-slate-700 border-2 border-slate-600 flex flex-col justify-between rounded-xl p-2 gap-4 cursor-pointer">
            <div className="flex justify-between">
              <div className="flex flex-col content">
                <div className="text-5xl font-semibold">{noofcourses}</div>
                <div className="text-2xl text-slate-300">Enrolled Courses</div>
              </div>
              <div className="logo bg-red-900/35 w-20 h-20 flex justify-center items-center p-3 rounded-full">
                <FaCrown className="text-6xl opacity-45" />
              </div>
            </div>
            <Link to={"./courses"} className="w-full">
              <div className="button border-2 border-slate-700 flex items-center justify-center p-2 rounded-xl cursor-pointer">
                View All
              </div>
            </Link>
          </div>
          <div className="card transition-all duration-[0.2s] bg-gradient-to-r from-slate-900 to-slate-700 border-2 border-slate-600 flex flex-col justify-between rounded-xl p-2 gap-4 cursor-pointer">
            <div className="flex justify-between">
              <div className="flex flex-col content">
                <div className="text-5xl font-semibold">{availableCourses}</div>
                <div className="text-2xl text-slate-300">Available Courses</div>
              </div>
              <div className="logo bg-red-900/35 w-20 h-20 flex justify-center items-center p-3 rounded-full">
                <IoBookSharp className="text-6xl opacity-45" />
              </div>
            </div>
            <Link to={"./allcourses"} className="w-full">
              <div className="button border-2 border-slate-700 flex items-center justify-center p-2 rounded-xl cursor-pointer">
                View All
              </div>
            </Link>
          </div>
          <div className="card transition-all duration-[0.2s] bg-gradient-to-r from-slate-900 to-slate-700 border-2 border-slate-600 flex flex-col justify-between rounded-xl p-2 gap-4 cursor-pointer">
            <div className="flex justify-between">
              <div className="flex flex-col content">
                <div className="text-5xl font-semibold">10+</div>
                <div className="text-2xl text-slate-300">Events</div>
              </div>
              <div className="logo bg-red-900/35 w-20 h-20 flex justify-center items-center p-3 rounded-full">
                <TbTimelineEventFilled className="text-6xl opacity-45" />
              </div>
            </div>
            <Link to={"./events"} className="w-full">
              <div className="button border-2 border-slate-700 flex items-center justify-center p-2 rounded-xl cursor-pointer">
                View All
              </div>
            </Link>
          </div>
          <div className="card transition-all duration-[0.2s] bg-gradient-to-r from-slate-900 to-slate-700 border-2 border-slate-600 flex flex-col justify-between rounded-xl p-2 gap-4 cursor-pointer">
            <div className="flex justify-between">
              <div className="flex flex-col content">
                <div className="text-5xl font-semibold">1500+</div>
                <div className="text-2xl text-slate-300">Members</div>
              </div>
              <div className="logo bg-red-900/35 w-20 h-20 flex justify-center items-center p-3 rounded-full">
                <IoIosPeople className="text-6xl opacity-45" />
              </div>
            </div>
            <Link to={"./courses"} className="w-full">
              <div className="button border-2 border-slate-700 flex items-center justify-center p-2 rounded-xl cursor-pointer">
                Join on WhatsApp
              </div>
            </Link>
          </div>
        </div>
      ) : isMobile ? null : (
        <div class="grid md:grid-cols-4 grid-cols-1 lg:grid-cols-4 gap-4">
          <div className="bg-[#1a2d48d9] rounded-xl h-[170px] animate-pulse p-4 flex flex-col justify-center items-center"></div>
          <div className="bg-[#1a2d48d9]  rounded-xl h-[170px] animate-pulse p-4 flex flex-col justify-center items-center"></div>
          <div className="bg-[#1a2d48d9] rounded-xl h-[170px] animate-pulse p-4 flex flex-col justify-center items-center"></div>
          <div className="bg-[#1a2d48d9] rounded-xl h-[170px] animate-pulse p-4 flex flex-col justify-center items-center"></div>
        </div>
      )}

      {/* <div className="about mt-4">
        <div className="head border-b-white border-b-2 py-3">
            <h1 className='text-2xl font-medium text-white'>About Insiden Community (1000+) Members</h1>
        </div>
        <p className='mt-1'>
            In publishing and graphic design, Lorem ipsum is a placeholder text commonly used to demonstrate the visual form of a document or a typeface without relying on meaningful content. Lorem ipsum may be used as a placeholder before the final copy is available. In publishing and graphic design, Lorem ipsum is a placeholder text commonly used to demonstrate the visual form of a document or a typeface without relying on meaningful content. Lorem ipsum may be used as a placeholder before the final copy is available. 
        </p>
    </div> */}
      {/* <div className="grid grid-col-1 md:grid-cols-4 lg:grid-col-4 md gap-4 mt-4">
        <div className="community-details bg-[#1a2d48d9] text-center rounded-md p-4">
            <div className="img opacity-50">
                <img src={logo} alt="" />
            </div>
            <h1 className="community-name text-2xl">
                Tech Community
            </h1>
            <p className="discrpn text-green-700">
                550+ Members 📈
            </p>
        </div>
        <div className="community-details bg-[#1a2d48d9] text-center rounded-md p-4">
            <div className="img opacity-50">
                <img src={logo} alt="" />
            </div>
            <h1 className="community-name text-2xl">
                Design Community
            </h1>
            <p className="discrpn text-green-700">
                550+ Members 📈
            </p>
        </div>
        <div className="community-details bg-[#1a2d48d9] text-center rounded-md p-4">
            <div className="img opacity-50">
                <img src={logo} alt="" />
            </div>
            <h1 className="community-name text-2xl">
                Startup Community
            </h1>
            <p className="discrpn text-green-700">
                550+ Members 📈
            </p>
        </div>
        <div className="community-details bg-[#1a2d48d9] text-center rounded-md p-4">
            <div className="img opacity-50">
                <img src={logo} alt="" />
            </div>
            <h1 className="community-name text-2xl">
                Jobs and Internships
            </h1>
            <p className="discrpn text-green-700">
                550+ Members 📈
            </p>
        </div>
    </div>
    <div className="powered p-4 text-center bg-[#1a2d48d9] mt-3 rounded-md flex flex-col items-center">
        <h1 className='text-3xl font-bold text-white'>Powered by www.strater.in</h1>
        <div className=''><img src={slogo} alt="" className='h-16 text-center mt-2' /></div>
    </div> */}

      <div className="grid grid-cols-6 gap-4 ">
        <div className="col-span-6 md:col-span-4 order-last md:-order-last">
          <div className="events bg-[#1a2d48d9] md:mt-4 rounded-xl p-4">
            <div className="header flex justify-between">
              <h1 className="text-2xl">Upcoming Events</h1>
              <Link to={"./events"}>
                <button className="text-slate-500 hover:text-slate-400 flex items-center gap-2">
                  View all
                  <FaEye />
                </button>
              </Link>
            </div>
            <hr className="mt-3 opacity-10" />
            <div className="crousel">
              <Swiper
                spaceBetween={30}
                centeredSlides={true}
                autoplay={{
                  delay: 5000,
                  disableOnInteraction: false,
                }}
                pagination={{
                  clickable: true,
                }}
                navigation={false}
                modules={[Autoplay, Pagination, Navigation]}
                className="mySwiper"
              >
                <SwiperSlide className="mt-4">
                  <div className="border-2 border-slate-600 rounded-xl flex flex-col gap-4 h-auto p-4 py-6">
                    <div className="flex md:flex-row flex-col flex-col-reverse gap-2">
                      <div className="content">
                        <h1 className="font-bold text-xl md:text-3xl text-gray-100/70 line-clamp-3">
                          How to win Hackathons, including preparation,
                          teamwork, and execution - Aryan Kyatham 7x Winner
                        </h1>
                        <p className="mt-2 text-slate-400 line-clamp-2">
                          His winning strategy and success story, Case studies
                          of successful startups that originated from
                          hackathons. Networking and funding opportunities for
                          hackathon projects.
                        </p>
                      </div>
                      <div className="image">
                        <img
                          src="https://firebasestorage.googleapis.com/v0/b/insiden-course-application.appspot.com/o/events%2FHow%20to%20win%20hackathons_session_.png?alt=media&token=276b0010-e710-456f-acaf-fb98d863e761"
                          alt=""
                          className="rounded-xl w-[580px] h-auto"
                        />
                      </div>
                    </div>
                    <div className="button flex justify-between items-center flex-col md:flex-row gap-2 md:gap-0">
                      <div className="flex justify-between gap-4 md:gap-0 items-center">
                        <p className="text-slate-500 rounded-xl flex items-center gap-2">
                          <BiCalendarMinus /> 29 June, 2024
                        </p>
                        <p className="text-slate-500 rounded-xl flex items-center gap-2">
                          <BiTimer /> 8:00 PM - 9:00 PM
                        </p>
                      </div>
                      <a href="https://forms.gle/i2miRDRnxaxxGyBc8" target="_blank">
                      <button className="bg-oran flex items-center gap-3 px-4 py-2 rounded-2xl bg-slate-900 w-full md:w-auto justify-center">
                        Register Now
                        <IoIosArrowForward className="animate-ping translate-x-[-8px]" />
                      </button>
                      </a>
                    </div>
                  </div>
                </SwiperSlide>
                {/* <SwiperSlide className="mt-4">
                  <div className="border-2 border-slate-600 rounded-xl flex flex-col gap-4 h-auto p-4 py-6">
                    <div className="flex md:flex-row flex-col flex-col-reverse gap-2">
                      <div className="content">
                        <h1 className="font-bold text-3xl text-gray-100/70 line-clamp-3">
                          Learn Web Architecture, Monolithic vs MicroServices,
                          Client vs Model
                        </h1>
                        <p className="mt-2 text-slate-400 line-clamp-2">
                          Lorem ipsum dolor sit amet, consectetur adipisicing
                          elit. Eum, quod quasi? Doloremque architecto eos.
                          consectetur adipisicing elit. Eum, quod quasi?{" "}
                        </p>
                      </div>
                      <div className="image">
                        <img
                          src="https://firebasestorage.googleapis.com/v0/b/insiden-course-application.appspot.com/o/events%2FAvishkar%20Hyperloop%20IIT%20Madras%20Thumbnail.png?alt=media&token=dd372c1b-2f8d-4ef6-a3b8-9e2e2316517a"
                          alt=""
                          className="rounded-xl w-[580px] h-auto"
                        />
                      </div>
                    </div>
                    <div className="button flex justify-between items-center flex-col md:flex-row gap-2 md:gap-0">
                      <div className="flex justify-between gap-4 md:gap-0 items-center">
                        <p className="text-slate-500 rounded-xl flex items-center gap-2">
                          <BiCalendarMinus /> 7 April, 2024
                        </p>
                        <p className="text-slate-500 rounded-xl flex items-center gap-2">
                          <BiTimer /> 5:00 PM - 6:30 PM
                        </p>
                      </div>
                      <button className="bg-oran flex items-center gap-3 px-4 py-2 rounded-2xl bg-slate-900 w-full md:w-auto justify-center">
                        Register Now
                        <IoIosArrowForward className="animate-ping translate-x-[-8px]" />
                      </button>
                    </div>
                  </div>
                </SwiperSlide> */}
              </Swiper>
            </div>
          </div>

          <LatestUpdate />
        </div>
        <div className="md:col-span-2 col-span-6">
          <UserInfo user={user} />
        </div>
      </div>
    </>
  );
}

export default Dashboard;
