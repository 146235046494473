import React, {useEffect, useState} from 'react';
import Sidebar from '../../components/Sidebar/Sidebar';
import Header from '../../components/Header/Header';
import slogo from "../../assets/images/slogo.png" ;
import courseImg from "../../assets/images/frontend-course.png";
import { Link, Outlet } from 'react-router-dom';
import { db } from '../../firebase';
import { collection, getDoc, getDocs, addDoc, doc } from 'firebase/firestore';
import { TbLoader } from "react-icons/tb";
import CardCourse from './CardCourse';
import { InfinitySpin } from 'react-loader-spinner';
import { useUserAuth } from '../../context/UserAuthContext';
import { FaCat } from 'react-icons/fa';
import { IoIosArrowForward } from 'react-icons/io';


function Courses() {

    const [courses, setCourses] = useState();
    const [isLoading, setIsLoading] = useState(true);
    const [courseDetails, setCourseDetails] = useState(null);
    const {user} = useUserAuth();

    
    const getCourses = async () => {
        console.log("fetcheing courses", user);
        try {
            setIsLoading(true);
            const querySnapshot = await getDocs(collection(db, "users", user.uid, "courses"));
            const coursesData = querySnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
            console.log(coursesData, "yehi hai console");
            if (coursesData.length > 0) {
                setCourses(coursesData);
                await fetchCourseDetails();
            } else {
                setIsLoading(false);
                console.log("37",isLoading);
            }
        } catch (err) {
            setIsLoading(false);
            console.log("41",isLoading);
            console.error("Error fetching courses:", err);
        }
    }

    const fetchCourseDetails = async () => {
        try {
            setIsLoading(true);
            const promises = courses.map(async (course) => {
                const docRef = doc(db, "Courses", course.CID);
                const docSnap = await getDoc(docRef);
                if (docSnap.exists()) {
                    return { id: course.id, ...docSnap.data() };
                } else {
                    console.log('No such document!');
                    return null;
                }
            });

            const courseDetailsData = await Promise.all(promises);
            setCourseDetails(courseDetailsData.filter(course => course !== null));
            setIsLoading(false);
            console.log("63",isLoading);
        } catch (error) {
            setIsLoading(false);
            console.log("66",isLoading);
            console.error('Error fetching course details:', error);
        }
    }

    useEffect(() => {
        if(user) {
            getCourses();
        }
        console.log("initial");
    }, [user]);

    useEffect(() => {
        console.log(courses);
        if (courses && courses.length > 0) {
            fetchCourseDetails();
            console.log(courses);
        }
    }, [courses]);

    useEffect(() => {
        console.log("Here is the all courses details:", courseDetails);
    }, [courseDetails]);
    


    // const getCourse = async (CID) => {
    //     setIsLoading(true);
    //     docSnap = await getDoc(collection(db, "users", user.uid, "courses", CID));
    //     console.log(docSnap, "comming from land")
    // }


    // const addData = async () => {
    //     try {
    //         const addData = await addDoc(collection(db, "users"), {
    //             firstName: "Ayush", 
    //             lastName: "Tiwari", 
    //             contace: "+918604262230"
    //         })
    //         console.log("data added success");
    //     } catch(err) {
    //         console.log(err)
    //     }
    // }



    // const docRef = await addDoc(collection(db, "cities"), {
    //     name: "Tokyo",
    //     country: "Japan"
    //   });
    //   console.log("Document written with ID: ", docRef.id);




    // const listAllCourses = async () => {
    //     return await getDoc(collection(db, "courses", "Q9yU99tsVpjBPBVdEbIV"));
    // }

    // useEffect(() => {
    //     listAllCourses().then((doc) => {
    //         console.log(doc)
    //     });
    // }, [])



    // const docRef = doc(db, "courses", "Q9yU99tsVpjBPBVdEbIV");
    // const docSnap = await getDoc(docRef);

    // if (docSnap.exists()) {
    //     console.log("Document data:", docSnap.data());
    // } else {
    // // docSnap.data() will be undefined in this case
    //     console.log("No such document!");
    // }
    

  return (
    <> 
        <div className="head py-3">
            <h1 className='text-2xl font-medium text-white border-b-orange-400'>Your <span className='text-orange-500'>Enrolled Courses</span></h1>
        </div>

        {!isLoading && !courseDetails ? 
        <div className='flex justify-center items-center h-[500px] bg-slate-800 rounded-2xl border-2 border-slate-600'>
            <div className="no-course flex flex-col justify-center items-center gap-2">
                <div className="no-img flex justify-center animate-bounce">
                    <FaCat className='text-8xl' />
                </div>
                <div className="sorrytext">
                    <h1 className='font-semibold text-2xl text-center text-slate-500'>Oops! No courses yet.<br />Start your learning journey today!</h1>
                </div>
                <div className="btn-cta">
                    <Link to={"/allcourses"}>
                    <div className="buynow flex items-center justify-center p-4 my-4 rounded-xl cursor-pointer bg-green-600 hover:bg-orange-700 gap-2 hover:scale-[1.02] pl-9">
                        <h1 className='font-semibold text-xl'>Explore Courses</h1>
                        <IoIosArrowForward className='animate-ping translate-x-[-8px]'/>
                    </div>
                    </Link>
                </div>
            </div>
        </div> 
        
        : null}
        
        <div className="grid grid-cols-1 md:grid-cols-3 lg:grid-cols-3 mt-3 gap-6">

        {/* {!isAnyCourse? <h1 className='text-red-500 text-3xl'>no courses</h1> : null} */}

        {courseDetails && !isLoading ? courseDetails.map((detail) => (
            
            <CardCourse 
            image={detail.CourseImage} 
            courseTitle={detail.CourseName} 
            duration={detail.DurationInMonth} 
            SubTitle={detail.SubTitle}
            courseId={detail.CID}
            InstructorName={detail.InstructorName}
            lang={detail.features[4]}
            hours={detail.HoursContent}
            />
            
            ))     
            :
            isLoading ?
            <>
            <div className="onecourse bg-slate-800 p-4 rounded-2xl border-2 border-slate-700  hover:cursor-pointer hover:translate-y-[-2px] transition-all duration-[0.15s] hover:shadow-2xl">
                <div className="course-img w-full h-40 bg-slate-600 animate-pulse rounded-xl">
                    
                </div>
                <div className='font-semibold text-[25px] my-2 w-full h-10'></div>
                <div className='pricing flex gap-4 mt-4 text-xl w-full h-8 bg-slate-600 rounded-xl animate-pulse'>    
                </div>
            </div>
            <div className="onecourse bg-slate-800 p-4 rounded-2xl border-2 border-slate-700  hover:cursor-pointer hover:translate-y-[-2px] transition-all duration-[0.15s] hover:shadow-2xl">
                <div className="course-img w-full h-40 bg-slate-600 animate-pulse rounded-xl">
                    
                </div>
                <div className='font-semibold text-[25px] my-2 w-full h-10'></div>
                <div className='pricing flex gap-4 mt-4 text-xl w-full h-8 bg-slate-600 rounded-xl animate-pulse'>    
                </div>
            </div>
            <div className="onecourse bg-slate-800 p-4 rounded-2xl border-2 border-slate-700  hover:cursor-pointer hover:translate-y-[-2px] transition-all duration-[0.15s] hover:shadow-2xl">
                <div className="course-img w-full h-40 bg-slate-600 animate-pulse rounded-xl">
                    
                </div>
                <div className='font-semibold text-[25px] my-2 w-full h-10'></div>
                <div className='pricing flex gap-4 mt-4 text-xl w-full h-8 bg-slate-600 rounded-xl animate-pulse'>    
                </div>
            </div>
            <div className="onecourse bg-slate-800 p-4 rounded-2xl border-2 border-slate-700  hover:cursor-pointer hover:translate-y-[-2px] transition-all duration-[0.15s] hover:shadow-2xl">
                <div className="course-img w-full h-40 bg-slate-600 animate-pulse rounded-xl">
                    
                </div>
                <div className='font-semibold text-[25px] my-2 w-full h-10'></div>
                <div className='pricing flex gap-4 mt-4 text-xl w-full h-8 bg-slate-600 rounded-xl animate-pulse'>    
                </div>
            </div>
            <div className="onecourse bg-slate-800 p-4 rounded-2xl border-2 border-slate-700  hover:cursor-pointer hover:translate-y-[-2px] transition-all duration-[0.15s] hover:shadow-2xl">
                <div className="course-img w-full h-40 bg-slate-600 animate-pulse rounded-xl">
                    
                </div>
                <div className='font-semibold text-[25px] my-2 w-full h-10'></div>
                <div className='pricing flex gap-4 mt-4 text-xl w-full h-8 bg-slate-600 rounded-xl animate-pulse'>    
                </div>
            </div>
            <div className="onecourse bg-slate-800 p-4 rounded-2xl border-2 border-slate-700  hover:cursor-pointer hover:translate-y-[-2px] transition-all duration-[0.15s] hover:shadow-2xl">
                <div className="course-img w-full h-40 bg-slate-600 animate-pulse rounded-xl">
                    
                </div>
                <div className='font-semibold text-[25px] my-2 w-full h-10'></div>
                <div className='pricing flex gap-4 mt-4 text-xl w-full h-8 bg-slate-600 rounded-xl animate-pulse'>    
                </div>
            </div>
            </>
            :
                null
            } 

        </div>

        <Outlet />
    </>
  )
}
export default Courses