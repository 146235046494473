import { IoRadioButtonOffSharp } from "react-icons/io5";
import { Link } from "react-router-dom";

const WeekLecture = ({
  WeekcourseId,
  weekId,
  LectureNo,
  LectureTitle,
  LecId,
  LecDesc,
  LecVideo,
  isMobileLecOpen, 
  setIsMobileLecOpen
}) => {
  return (
    <Link to={`../course-study/${WeekcourseId}/${weekId}/${LecId}`} onClick={() => setIsMobileLecOpen(!isMobileLecOpen)}>
      <div className="lecture my-2 hover:bg-slate-700 hover:text-white transition-all cursor-pointer p-3 rounded-md border-2 border-gray-500">
        <div className="flex items-center gap-2 z-30">
          <div className="w-[5%]">
            <IoRadioButtonOffSharp />
          </div>
          <div className="w-[95%]">
            <h1 className="line-clamp-3">{LectureTitle}</h1>
          </div>
        </div>
      </div>
    </Link>
  );
};

export default WeekLecture;
