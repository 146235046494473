import React, { useEffect, useState } from 'react';
import logoInsiden from "../../assets/images/logo_wide.png";
import { Link, useNavigate } from 'react-router-dom';
import paymentLogo from "../../assets/images/payment_icon_list.png";
import qrScan from "../../assets/images/qr-pay.png"
import { useUserAuth } from '../../context/UserAuthContext';
import DateCountdown from 'react-date-countdown-timer';
import {useParams} from "react-router-dom";
import { addDoc, collection, doc, setDoc } from 'firebase/firestore';
import { db } from '../../firebase';


function Signup() {

  const { courseId } = useParams();
  const { signUp } = useUserAuth();
  const [error, setError] = useState();
  let navigate = useNavigate();

  const [userData, setUserData] = useState({
    fullName: "",
    email: "",
    password: "",
    confirmPassword: "",
    tid: "",
    cid: courseId,
    whatsAppno: "",
    userType: "student",
  });

  useEffect(() => {
    console.log(userData);
  }, [userData])

  
  const handleChange = (e) => {
    const name = e.target.name;
    const value = e.target.value;
    
    setUserData((prev) => {
      return {...prev, [name]: value}
    }) 
  }

  const handleSignup = async (e) => {
    e.preventDefault();
    setError("");
    try {
      signUp(userData.email, userData.password)
      .then(async (response) => {
        try {
          
        } catch (error) {
          console.log("Setting user data to firestore Failed")
        }
      })
    } catch (error) {
      console.log("Failed to get user SignUp");
    }
  }
  
  // const handleSignup = async (e) => {
  //   e.preventDefault();
  //   setError("");
  //   try {
  //     signUp(userData.email, userData.password)
  //       .then(async (response) => {
  //         try {
  //           const userRef = collection(db, "users");
  //           await userRef.setDoc(doc(db, "users", response.uid), {
  //             Email: userData.email,
  //             FullName: userData.fullName,
  //             UID: response.uid,
  //             whatsAppNo: userData.whatsAppno,
  //           });   
  //           console.log("User other data is strored to the databse and you can now see in the database");
  //           navigate("/courses");        
  //         } catch (error) {
  //           console.log("User data is not saved", error)
  //         }
  //       })
      
  //     console.log("second")
  //     console.log("fdshfjdshjh", "Data saved to firebase store");
  //   } catch (err) {
  //     setError(err.message);
  //     console.log("error hai")
  //   }
  // }

  // const handleSubmit = async (e) => {
  //   e.preventDefault();
  //   setError("");
  //   try {
  //     await signUp(email, password);
  //     navigate("/");
  //   } catch (err) {
  //     setError(err.message);
  //   }
  // };

  const [counter, setCounter] = React.useState(180);

  // First Attempts
  setInterval(() => setCounter(counter - 1), 1000);

  return (
    <>
    <div className="timmer w-full py-1 bottom-0 fixed flex justify-center items-center bg-red-600">
      <h1 className='text-2xl text-center'>Mentorship Program FREE with Course - <b className='animate-pulse'>Offer Expires Very Soon!</b></h1>
    </div>
      <div className="payment md:w-2/5 bg-slate-800 my-8 m-auto rounded-md border-slate-600 border-2 border-t-orange-600 border-t-8 p-4">
        <div className='flex justify-center mt-2'>
          <img src={logoInsiden} alt="" className='w-56 h-auto self-center'/>
        </div>

            <div className="text text-center my-6 text-slate-300">
              <h1 className='font-bold text-4xl'>3-Month Frontend<br />Web Development Program </h1>
            </div>
            
            <form className="space-y-4 md:space-y-6" action="#">              
                <div>
                    <label htmlFor="text" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Full Name</label>
                    <input type="email" name="fullName" id="email" className="bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" placeholder="Your Name" required="" onChange={handleChange} />
                </div>
                <div>
                    <label htmlFor="email" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Your Email</label>
                    <input type="email" name="email" id="email" className="bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" placeholder="name@XYZ.com" required="" onChange={handleChange} />
                </div>
                <div>
                    <label htmlFor="password" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Password</label>
                    <input type="password" name="password" id="password" placeholder="••••••••" className="bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" required="" onChange={handleChange} />
                </div>
                <div>
                    <label htmlFor="confirm-password" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Confirm password</label>
                    <input type="confirm-password" name="confirmPassword" id="confirm-password" placeholder="••••••••" className="bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" required="" onChange={handleChange} />
                </div>

                <div className="qr-scan flex flex-col"> 
                  <div className='text-center'>
                    <p className='text-slate-400'>Scan the QR Code with any UPI App and pay the           
                    amount then using above email and password access course.</p> 
                    <h1 className='text-4xl'>₹<strike className="text-red-500">3999</strike> Now only in <b className='text-green-400'>₹499/-</b></h1>     
                  </div>
                  <div className="timmer text-center mt-2">
                      <p>Price increase in <b className='text-red-500'>{counter} Seconds!</b></p>
                  </div>
                  <div className='flex justify-center'>
                    <img src={qrScan} className='w-50 h-auto rounded-md bg-white mt-4' alt="" />
                  </div>             
                </div>

                <div className="iconspay flex justify-center items-center">
                  <img src={paymentLogo} className='w-4/5 rounded-md h-auto bg-zinc-600' alt="" />
                </div>

                <div>
                    <label htmlFor="password" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Transaction ID ***</label>
                    <input type="text" name="tid" id="tid" placeholder="T2403211142557048346421" className="bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" required="" onChange={handleChange} />
                </div>
                <div>
                    <label htmlFor="email" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">WhatsApp No.</label>
                    <input type="number" name="whatsAppno" id="whatsAppno" className="bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" placeholder="" required="" onChange={handleChange} />
                </div>

                <div className="flex items-start">
                    <div className="flex items-center h-5">
                      <input id="terms" aria-describedby="terms" type="checkbox" className="w-4 h-4 border border-gray-300 rounded bg-gray-50 focus:ring-3 focus:ring-primary-300 dark:bg-gray-700 dark:border-gray-600 dark:focus:ring-primary-600 dark:ring-offset-gray-800" required="" />
                    </div>
                    <div className="ml-3 text-sm">
                      <label htmlFor="terms" className="font-light text-gray-500 dark:text-gray-300">I accept the <a className="font-medium text-primary-600 hover:underline dark:text-primary-500" href="/">Terms and Conditions</a></label>
                    </div>
                </div>
                <button onClick={handleSignup} type="submit" className="transition-all w-full text-white bg-green-600 hover:bg-green-700 focus:ring-4 focus:outline-none focus:ring-primary-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:bg-primary-600 dark:hover:bg-primary-700 dark:focus:ring-primary-800">Pay and Create an Account</button>
                
                <p className="text-sm font-light text-gray-500 dark:text-gray-400">
                    Already enrolled in the course and have an account? <Link to={"/login"} className="font-medium text-primary-600 hover:underline dark:text-primary-500">Login here</Link>
                </p>

            </form>
        </div>
    </>
  )
}

export default Signup