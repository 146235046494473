import React, { useState } from "react";
import Sidebar from "../../components/Sidebar/Sidebar";
import Header from "../../components/Header/Header";
import { Link, Navigate, Outlet, useParams } from "react-router-dom";
import { createContext } from "react";
import { RiWhatsappFill } from "react-icons/ri";
import { useLoadingContext } from "../../context/LoadingContext";
import { useMobileScreenContext } from "../../context/MobileScreen";
import { IoMdCloseCircle, IoMdCloseCircleOutline } from "react-icons/io";
import { options } from "../../components/Sidebar/sidebarOptions";
import { BiLogOut } from "react-icons/bi";
import { IoArrowBackCircle } from "react-icons/io5";
import { useUserAuth } from "../../context/UserAuthContext";
import Swal from "sweetalert2";
import { motion } from "framer-motion";
import { AnimatePresence } from "framer-motion";

export const SidebarState = createContext();

function Layout() {
  const { isMobile, setIsMobileNavbar, isMobileNavbar, toggleIsMobileNavbar } =
    useMobileScreenContext();

  const { loading, setLoading, ShowLoading } = useLoadingContext();
  const { courseId, weekId, lectureId } = useParams();

  const [isOpen, setIsOpen] = useState(true);

  const ToggleButtonSidebar = () => {
    setIsOpen(!isOpen);
    console.log("Sidebar Active", isOpen);
  };

  const { logOut, user } = useUserAuth();

  const handleLogout = async () => {
    try {
      Swal.fire({
        title: "Are you sure?",
        text: "You won't be able to revert this!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#d33",
        cancelButtonColor: "#fd6713",
        confirmButtonText: "Yes, log out!",
      }).then(async (result) => {
        if (result.isConfirmed) {
          setLoading(true);
          await logOut();
          setLoading(false);
          Navigate("/login");
        }
      });
    } catch (error) {
      console.log(error.message);
      setLoading(false);
    }
  };

  const screenNavComponent = {
    hidden: {
      opacity:0,
      y: '2vh',
    },
    visible: {
      opacity:1,
      y:0,
    }
  }

  return (
    <SidebarState.Provider value={{ ToggleButtonSidebar, isOpen, setIsOpen }}>
      {loading && <ShowLoading />}

      {isMobile && isMobileNavbar ? (
      <AnimatePresence>
        <motion.div className="fullscreennav bg-gradient-to-tr from-slate-700 to-gray-800 w-full fixed h-screen z-50 p-8"
        variants={screenNavComponent}
        initial="hidden"
        animate="visible"
        exit={{ exit:0 }}
        >
          <div className="nav-content flex justify-between">
            <div>
              <h1 className="text-2xl bg-white p-2 text-orange-600 px-4 rounded-full font-semibold">
                Insiden
              </h1>
            </div>
            <div
              onClick={toggleIsMobileNavbar}
              className="flex gap-1 items-center justify-center bg-slate-700 px-4 border-2 border-slate-600 rounded-full hover:bg-slate-600"
              >
              <IoArrowBackCircle className="text-3xl" />{" "}
              <h1 className="text-base">Back</h1>
            </div>
          </div>
          <div className="grid grid-cols-6 gap-4 menu-small w-full p-8 mt-8 rounded-xl">
            {options.map((option) => (
              <Link
              to={option.menuLink}
              onClick={toggleIsMobileNavbar}
              className="bg-slate-700 hover:bg-slate-600 py-6 p-4 col-span-3 flex flex-col gap-2 items-center rounded-xl px-2"
              >
                <div className="icon text-4xl">{option.menuIcon}</div>
                <div className="icon-name">
                  <h1 className="text-base">{option.menuName}</h1>
                </div>
              </Link>
            ))}

            <Link
              onClick={handleLogout}
              className="border-2 border-slate-600 hover:bg-slate-700 p-4 col-span-6 flex justify-center gap-2 items-center rounded-full text-2xl"
              >
              <div className="icon">
                <BiLogOut />
              </div>
              <div className="icon-name">Logout</div>
            </Link>
          </div>
        </motion.div>
      </AnimatePresence>
      ) : null}

      <div>
        {/* <button data-drawer-target="default-sidebar" data-drawer-toggle="default-sidebar" aria-controls="default-sidebar" type="button" className="inline-flex items-center p-2 mt-2 ms-3 text-sm text-gray-500 rounded-lg sm:hidden hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-gray-200 dark:text-gray-400 dark:hover:bg-gray-700 dark:focus:ring-gray-600">
        <span className="sr-only">Open sidebar</span>
        <svg className="w-6 h-6" aria-hidden="true" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
        <path clipRule="evenodd" fillRule="evenodd" d="M2 4.75A.75.75 0 012.75 4h14.5a.75.75 0 010 1.5H2.75A.75.75 0 012 4.75zm0 10.5a.75.75 0 01.75-.75h7.5a.75.75 0 010 1.5h-7.5a.75.75 0 01-.75-.75zM2 10a.75.75 0 01.75-.75h14.5a.75.75 0 010 1.5H2.75A.75.75 0 012 10z" />
        </svg>
        </button> */}

        <Sidebar />

        <div className={isOpen ? "sm:ml-60" : "sm:ml-[65px]"}>
          <div className="bg-cyan-950 shadow-md shadow-neutral-900 text-white sticky top-0 z-30">
            <Header />
          </div>
          <div className={!isOpen ? "main p-4 container m-auto" : "main p-4"}>
            <Outlet />
          </div>
        </div>
      </div>

      <a
        href="https://chat.whatsapp.com/Fcd8ND2oOFB2qM9tBmi0Vf"
        target="_blank"
        >
        <div className="fixed bottom-8 z-40 right-9 p-3 border-2 border-gray-500 bg-slate-600 hover:bg-slate-700 transition-all cursor-pointer rounded-full shadow-xl">
          <RiWhatsappFill className="text-3xl text-green-300" />
        </div>
      </a>
    </SidebarState.Provider>
  );
}

export default Layout;
