import React from "react";
import { FaPlay } from "react-icons/fa";
import { MdLogin } from "react-icons/md";
import { Link } from "react-router-dom";

const Hero = () => {
  return (
    <>
      <dialog id="my_modal_4" className="modal">
        <div className="modal-box w-11/12 max-w-5xl">
          <h3 className="font-bold text-lg">
            How to win Hackathon? with Aryan Kyatham (International Hackathon
            Winner 7x) 🏆
          </h3>
          <iframe
            width="100%"
            src="https://www.youtube.com/embed/gFR6jDxqUbc?si=CdV_LlopQiGzIZOQ"
            title="YouTube video player"
            frameborder="0"
            autoplay="1"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
            referrerpolicy="strict-origin-when-cross-origin"
            allowfullscreen
            className="md:h-[500px] mt-4"
          ></iframe>
          <div className="modal-action">
            <form method="dialog">
              {/* if there is a button, it will close the modal */}
              <button className="btn">Close</button>
            </form>
          </div>
        </div>
      </dialog>

      <div className="w-11/12 m-auto h-auto p-8 pt-12 md:pt-20 pb-10 flex items-center justify-center">
        <div className="flex flex-col gap-4">
          <p className="text-center text-xl">
            Together we're defining what is possible
          </p>
          <h1 className="text-5xl font-bold text-center">Learn, Connect and Collaborate</h1>
          <div className="p-4 flex flex-col md:flex-row justify-center items-center gap-2">
            <button
              className="flex justify-center items-center gap-2 px-8 bg-orange-600 border-4 border-slate-700 p-3 rounded-full animate-pulse"
              onClick={() => document.getElementById("my_modal_4").showModal()}
            >
              <FaPlay className="md:text-4xl text-2xl" />
              <h1 className="text-xl">Latest Event</h1>
            </button>
            <Link
              to={"/login"}
              className="flex justify-center items-center gap-2 px-8 bg--600 border-4 border-slate-700 p-3 rounded-full"
            >
              <MdLogin className="md:text-4xl text-2xl" />
              <h1 className="text-xl">Login</h1>
            </Link>
          </div>
          <p className="text-center font-sans text-xl">
            A community for student growth and success
          </p>
        </div>
      </div>

      <div className="flex justify-center items-center pb-14 md:pb-24">
        <div className="stats stats-vertical lg:stats-horizontal shadow">
          <div className="stat">
            <div className="stat-value">10+</div>
            <div className="stat-title">Events</div>
          </div>

          <div className="stat">
            <div className="stat-value">1,500+</div>
            <div className="stat-title">Members</div>
          </div>

          <div className="stat">
            <div className="stat-value">1,200+</div>
            <div className="stat-title">Jobs posted</div>
          </div>
        </div>
      </div>
    
    </>
  );
};

export default Hero;
