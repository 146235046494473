import React from "react";
import { Navigate } from "react-router-dom";
import { useUserAuth } from "../context/UserAuthContext";
import { Puff, TailSpin } from "react-loader-spinner";
import LogoWide from "../assets/images/logo_wide.png";

const ProtectedRoute = ({ children }) => {
  const { user, isLoading } = useUserAuth();
  if (isLoading) {
    return (
      <>
        <div className="h-screen w-full flex flex-col justify-center items-center">
          {LogoWide ? (
            <>
              <img
                src={LogoWide}
                alt="Insiden_Logo"
                className="h-14 animate-pulse"
              />

              <h1 className="mt-4 text-3xl font-bold p-1">Welcome Back 👋</h1>

              {/* <TailSpin
                className="mt-4 p-4"
                visible={true}
                height="50"
                width="50"
                color="orange"
                ariaLabel="tail-spin-loading"
                radius="1"
                wrapperStyle={{}}
                wrapperClass=""
              /> */}
            </>
          ) : (
            <Puff
              visible={true}
              height="80"
              width="80"
              color="orange"
              ariaLabel="puff-loading"
              wrapperStyle={{}}
              wrapperClass=""
            />
          )}
        </div>
      </>
    );
  }
  console.log("Check user in Private: ", user);
  if (!user && !isLoading) {
    return <Navigate to="/login" />;
  }
  return children;
};

export default ProtectedRoute;
