import React, { useEffect, useState } from 'react';
import { IoIosAirplane, IoIosArrowDown, IoIosArrowDropleft, IoIosArrowDropright, IoIosArrowUp } from "react-icons/io";
import { IoRadioButtonOffSharp } from "react-icons/io5";
import Week from "./Week";
import { collection, getDocs } from 'firebase/firestore';
import { db } from '../firebase';
import { RiH1 } from 'react-icons/ri';
import { Link } from 'react-router-dom';
import { InfinitySpin } from 'react-loader-spinner'
import { useMobileScreenContext } from '../context/MobileScreen';


const WeeksSidebar = ({WeekcourseId, isMobileLecOpen, setIsMobileLecOpen}) => {

  const [theWeekStatefromChild, setTheWeekStatefromChild] = useState({});

  const [weeks, setWeeks] = useState();

  const [openWeeks, setOpenWeeks] = useState(true);
  
  const { isMobile, setIsMobileNavbar, isMobileNavbar } =
  useMobileScreenContext();

  const HandleWeeksSidebar = () => {
    setOpenWeeks(!openWeeks);
    console.log("WeeksSidebar", openWeeks);
  }

    
  const getWeeks = async () => {
      try {
          const querySnapshot = await getDocs(collection(db, "Courses", WeekcourseId, "weeks"));
          const weeksData = querySnapshot.docs.map(doc => ({id: doc.id, ...doc.data()}));
          setWeeks(weeksData);
          console.log(weeksData);
          const options = {}
          for (let i = 0; i < weeksData.length; i++) {
            options[weeksData[i].id] = false;
          }
          setTheWeekStatefromChild(options);
          console.log("***",theWeekStatefromChild);
      } catch(err) {
          console.log("not done", err);
      }
  }
  
  useEffect(() => {
      getWeeks();
  }, []);
  


  const HandleWeekState = (weekIdClicked, stateIs) => {
    console.log(weekIdClicked, stateIs);

    const options = {}

    if(stateIs === true) {
      for (let i = 0; i < weeks.length; i++) {
        options[weeks[i].id] = !stateIs;
      }
    }

    options[weekIdClicked] = stateIs;
    setTheWeekStatefromChild(options);
    console.log(theWeekStatefromChild);
  }

  return (
    <>
    { openWeeks ?
    <div className={`${!isMobile ? "col-span-2" : "col-span-8"} bg-slate-800 rounded-md p-2`}>
      <div className="weeks-toggle flex justify-end px-2 mt-2" onClick={HandleWeeksSidebar}>
        <IoIosArrowDropleft className='text-2xl text-slate-500 hover:text-slate-300 transition-all cursor-pointer'/>
      </div>
      <div className='sticky top-24'>
          {/* <Week weekId="Week-1" />
          <Week weekId="Week-2" />
          <Week weekId="Week-3" /> */}

          {weeks && weeks.length === 0 ? 
          <div className='bg-slate-700 border-2 border-slate-500 p-4 mt-4 rounded-2xl flex justify-center items-center flex-col h-[500px]'>
            <IoIosAirplane className='text-8xl animate-pulse' />
            <h1 className='text-center'>The Week Content Will Release Soon! Thanks for your patience</h1>
            </div> 
            : null}

          {weeks && theWeekStatefromChild ? weeks.map((week) => <Week isMobileLecOpen={isMobileLecOpen} setIsMobileLecOpen={setIsMobileLecOpen} weekId={week.id} WeekcourseId={WeekcourseId} onState={HandleWeekState} theWeekStatefromChild={theWeekStatefromChild} />) : 
          <InfinitySpin className="justify-center items-center"
          visible={true}
          width="200"
          color="orange"
          ariaLabel="infinity-spin-loading"
          />}
      </div>
    </div>
    :
    <>
    <div className="col-span-1">
    <div className="weeks-toggle flex justify-end px-2 mt-2" onClick={HandleWeeksSidebar}>
        <IoIosArrowDropright className='text-2xl text-slate-500 hover:text-slate-300 transition-all cursor-pointer'/>
      </div>
    </div>
    </>
    }
    </>
  )
}

export default WeeksSidebar