import { AiFillDashboard } from "react-icons/ai";
import { BiSolidFactory } from "react-icons/bi";
import { CgLogOut } from "react-icons/cg";
import { FaCrown } from "react-icons/fa";
import { MdAssignment, MdAssignmentTurnedIn, MdExplore, MdOutlineEventAvailable } from "react-icons/md";
import { IoBookSharp } from "react-icons/io5";
import { TbTimelineEventFilled } from "react-icons/tb";


export const options = [
    {
        menuName: "My Courses",
        menuLink: "/portal/courses",
        menuIcon: <FaCrown />,
    }, 
    {
        menuName: "Dashboard",
        menuLink: "/portal",
        menuIcon: <AiFillDashboard />,
    }, 
    {
        menuName: "All Courses",
        menuLink: "/portal/allcourses",
        menuIcon: <IoBookSharp />,
    },
    {
        menuName: "Events",
        menuLink: "/portal/events",
        menuIcon: <TbTimelineEventFilled />        ,
    },
    {
        menuName: "Assignments",
        menuLink: "/portal/assignments",
        menuIcon: <MdAssignment />,
    },
    {
        menuName: "Products",
        menuLink: "/portal/",
        menuIcon: <BiSolidFactory />,
    },
]