import React, { useEffect, useState } from "react";
import bannerImg from "../assets/images/frontend-course.png";
import { doc, getDoc } from "firebase/firestore";
import { db } from "../firebase";
import { useParams } from "react-router-dom";
import { ThreeDots } from "react-loader-spinner";
import { MdCalendarMonth, MdFullscreen } from "react-icons/md";
import { useMobileScreenContext } from "../context/MobileScreen";
import { IoArrowBackCircleSharp, IoArrowForwardCircleSharp } from "react-icons/io5";

const Content_study = ({ isMobileLecOpen, setIsMobileLecOpen }) => {
  const { courseId } = useParams();
  const [courseDetails, setCourseDetails] = useState();
  const { isMobile, setIsMobileNavbar, isMobileNavbar } =
    useMobileScreenContext();

  const getCourseDetails = async () => {
    try {
      const docRef = doc(db, "Courses", courseId);
      const docSnap = await getDoc(docRef);
      setCourseDetails(docSnap.data());
      console.log(docSnap.data());
    } catch (err) {
      console.log("not done", err);
    }
  };

  useEffect(() => {
    getCourseDetails();
    setIsMobileLecOpen(true);
  }, []);

  return (
    <>
      {courseDetails ? (
        <div
        className={`${isMobile ? "col-span-8" : "col-span-6"} ${
          isMobileLecOpen &&
          isMobile &&
          "fixed top-[66px] bottom-0 overflow-y-scroll mr-4 flex flex-col"
        } bg-slate-700 rounded-md p-4 flex flex-col`}
      >
          {isMobile && (
            <>
              <button onClick={() => setIsMobileLecOpen(!isMobileLecOpen)}>
                <div className="flex bg-slate-600 border-2 border-slate-500 p-2 mb-2 justify-center items-center gap-1 rounded-full px-4">
                  {isMobileLecOpen ? (
                    <>
                      <h1>Go to lectures</h1>
                      <IoArrowForwardCircleSharp className="text-2xl" />{" "}
                    </>
                  ) : (
                    <>
                      <MdFullscreen className="text-2xl" /> <h1>Maximize</h1>
                    </>
                  )}
                </div>
              </button>
            </>
          )}
          <h1 className="text-2xl">About Course:</h1>
          <div className="course-img mt-4">
            {!courseDetails ? (
              <div className="h-screen bg-red-600">
                fdsfdsdsfdjlfsjfksjkfakdnfsjk
              </div>
            ) : (
              <img
                src={courseDetails.CourseImage}
                alt=""
                className="rounded-md"
              />
            )}
          </div>
          <h1 className="mt-2 text-2xl font-medium text-slate-300">
            Message from the Instructor
          </h1>
          <p className="mt-4">{courseDetails.MFI}</p>

          <div className="grid grid-cols-6 mt-4 gap-6">
            <div className="col-span-2 bg-slate-600 rounded-md p-4 flex flex-col items-center border-2 border-slate-400">
              <div>
                <h1 className="font-bold text-3xl text-green-500">
                  {courseDetails.DurationInMonth}+
                </h1>
              </div>
              <div>
                <h1 className="text-slate-300">Month</h1>
              </div>
            </div>
            <div className="col-span-2 bg-slate-600 rounded-md p-4 flex flex-col items-center border-2 border-slate-400">
              <div>
                <h1 className="font-bold text-3xl text-green-500">20+</h1>
              </div>
              <div>
                <h1 className="text-slate-300">Live Session</h1>
              </div>
            </div>
            <div className="col-span-2 bg-slate-600 rounded-md p-4 flex flex-col items-center border-2 border-slate-400">
              <div>
                <h1 className="font-bold text-3xl text-green-500">5+</h1>
              </div>
              <div>
                <h1 className="text-slate-300">Projects</h1>
              </div>
            </div>
            {/* <div className='col-span-6 text-center bg-slate-500 transition-all border-2 border-slate-400 p-4 rounded-md cursor-pointer hover:bg-slate-400'>
          Join our WhatsApp Group from Insiden for Mentorsip
        </div> */}
          </div>

          {/* <h1 className='mt-4 text-2xl font-medium text-slate-300'>Recommended Resourses</h1>
      <div className='text-slate-400 p-4'>
        <li className='mb-3'><a href="https://www.w3schools.com/" target='blank'>React Documentation</a></li>
        <li className='mb-3'><a href="https://www.w3schools.com/" target='blank'>MDN Documentation</a></li>
        <li className='mb-3'><a href="https://www.w3schools.com/" target='blank'>W3schools</a></li>
        <li className='mb-3'><a href="https://www.w3schools.com/" target='blank'>FreeCodeCamp</a></li>
        <li className='mb-3'><a href="https://www.w3schools.com/" target='blank'>W3schools</a></li>
      </div> */}
        </div>
      ) : (
        <div className={`${!isMobile ? "col-span-6" : "col-span-8"} flex justify-center items-center bg-slate-800 rounded-md w-full col-span-6 animate-pulse`}>
          <ThreeDots
            visible={true}
            width="200"
            color="orange"
            ariaLabel="infinity-spin-loading"
          />
        </div>
      )}
    </>
  );
};

export default Content_study;
