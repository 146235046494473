import React from 'react'

const Work = () => {
  return (
    <div className='bg-red-600 p-8'>
        This is our work section under development
    </div>
  )
}

export default Work