import React, { useEffect } from 'react'
import Logo from "../../assets/images/logo_wide.png"
import { useState } from "react";
import { Link, useNavigate } from 'react-router-dom';
import { useUserAuth } from '../../context/UserAuthContext';
import googleLogo from "../../assets/images/google-icon.png";
import { FaGoogle } from "react-icons/fa";
import { IoIosArrowForward, IoIosWarning } from "react-icons/io";
import { FcGoogle } from "react-icons/fc";
import { BiSolidError } from 'react-icons/bi';
import { InfinitySpin, ProgressBar } from 'react-loader-spinner';
import { useLoadingContext } from '../../context/LoadingContext';
import { db } from '../../firebase';
import { doc, getDoc, setDoc } from 'firebase/firestore';
import { delay, motion } from 'framer-motion';


const Login = () => {
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [error, setError] = useState("");
    const { loading, setLoading, ShowLoading } = useLoadingContext();
    const { user, setUser, logIn, googleSignIn } = useUserAuth();
    const navigate = useNavigate();

    useEffect(() => {
      if (user !== null) {
        navigate("/portal");
      }
    },[])
  
    const handleSubmit = async (e) => {
      e.preventDefault();
      setError("");
      try {
        setLoading(true)
        await logIn(email, password);
        setLoading(false)
        navigate("/portal");
      } catch (err) {
        setError(err.message);
        setLoading(false);
      }
    };
  
    const handleGoogleSignIn = async (e) => {
      e.preventDefault();
      try {
        setLoading(true);
        const googleData = await googleSignIn();
        const userData = {
          userID: googleData.user.uid,
          email: googleData.user.email,
          fullName: googleData.user.displayName,
          photoURL: googleData.user.photoURL,
          whatsAppNo: ""
        }
        console.log("user loggedIn", userData);

        new Promise((resolve, reject) => {
          const docRef = doc(db, "users", userData.userID);
          setDoc(docRef, userData) // Set user data in Firestore
            .then(() => {
              resolve(); // Resolve the promise after data is successfully set
            })
            .catch((error) => {
              reject(error); // Reject the promise if there's an error
            });
        }).then(() => {
          setLoading(false);
          navigate("/portal");
          console.log(userData);
        })
        .catch((error) => {
          console.log("Error setting user data in Firestore:", error);
        });

        // checkUserExists(userData);
        // saveUserDataToFirestore(userData);
      } catch (error) {
        console.log(error.message);
      }


    };
    
    // const checkUserExists = (userData) => {
    //   const userRef = db.firestore().collection('users').doc(userData.uid);
    //   userRef.get()
    //     .then(doc => {
    //       if (doc.exists) {
    //         console.log('User already exists');
    //         // Proceed with login
    //       } else {
    //         console.log('User does not exist, saving data to Firestore');
    //         // Save user data to Firestore
    //         saveUserDataToFirestore(userData);
    //       }
    //     })
    //     .catch(error => {
    //       console.error('Error checking user existence:', error);
    //     });
    // };

    // const saveUserDataToFirestore = (userData) => {
    //     db.collection('users').doc(userData.uid).set(userData)
    //       .then(() => {
    //         console.log('User data saved successfully');
    //       })
    //       .catch(error => {
    //         console.error('Error saving user data:', error);
    //         setError(error);
    //       });
    // };


  const LogoAnimate = {
    hidden: {
      opacity:0,
      x: 80,
    },
    visible: {
      opacity:1,
      x:0,
    }
  }

  const LoginAnimate = {
    hidden: {
      opacity:0,
      y: '2vh',
    },
    visible: {
      opacity:1,
      y:0,
      transition: {
        delay: 0.08,
      }
    }
  }
  
  return (
    <>
    {/* {error && <h1 className='text-red-600'>{error}</h1>} */}

    {loading && <ShowLoading />}

    <section className="bg-gray-900">
      <div className="flex flex-col items-center justify-center px-6 py-8 mx-auto h-screen lg:py-0">
          <motion.a href="#" className="flex items-center mb-6 text-2xl font-semibold text-white"
            variants={LogoAnimate}
            initial="hidden"
            animate="visible"
          >
              <img className="w-full h-14 mr-2" src={Logo} alt="logo" />
          </motion.a>
          <motion.div className="w-full rounded-lg shadow  border md:mt-0 sm:max-w-md xl:p-0 bg-gray-800 border-gray-700"
            variants={LoginAnimate}
            initial="hidden"
            animate="visible"
          >
              <div className="p-6 space-y-4 md:space-y-6 sm:p-8">
                  <h1 className="text-xl font-bold leading-tight tracking-tight md:text-2xl text-white">
                      Sign in to your account
                  </h1>
                  <button type="submit" className="w-full text-white bg-slate-800 border-2 border-slate-600 hover:bg-slate-900 font-medium rounded-full text-sm px-5 py-2.5 text-center hover:scale-[1.02] hover:shadow-xl transition-all duration-75" onClick={handleGoogleSignIn}>
                        <div className='flex justify-center items-center gap-2'>
                          <FcGoogle className='text-2xl text-red-500' /><p>Continue with Google </p>
                          <IoIosArrowForward className='animate-ping translate-x-[-8px]'/>
                        </div>
                      </button>
                      <div class="relative flex py-2 items-center">
                          <div class="flex-grow border-t border-gray-500"></div>
                          <span class="flex-shrink mx-4 text-gray-400">or login with</span>
                          <div class="flex-grow border-t border-gray-500"></div>
                      </div>
                  {/* {error && <h1 className='text-red-600'>{error}</h1>} */}
                  {error && <h1 className='bg-red-600 p-2 rounded-md flex items-center justify-center gap-2'><BiSolidError className='text-2xl' /> Error: Please Enter a valid Email and Password</h1>}
                  <form className="space-y-4 md:space-y-6" onSubmit="">
                      <div>
                          <label for="email" className="block mb-2 text-sm font-medium text-white">Your email</label>
                          <input required type="email" name="email" id="email" className="border sm:text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5  bg-gray-700  border-gray-600  placeholder-gray-400  text-white  focus:ring-blue-500  focus:border-blue-500" placeholder="name@example.com" 
                          onChange={(e) => setEmail(e.target.value)}
                          />
                      </div>
                      <div>
                          <label for="password" className="block mb-2 text-sm font-medium  text-white">Password</label>
                          <input type="password" name="password" id="password" placeholder="••••••••" className="sm:text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5  bg-gray-700  border-gray-600  placeholder-gray-400  text-white  focus:ring-blue-500  focus:border-blue-500"
                          onChange={(e) => setPassword(e.target.value)}
                           />
                      </div>
                      {/* <div className="flex items-center justify-between"> */}
                          {/* <div className="flex items-start">
                              <div className="flex items-center h-5">
                                <input id="remember" aria-describedby="remember" type="checkbox" className="w-4 h-4 border border-gray-300 rounded bg-gray-50 focus:ring-3 focus:ring-primary-300  bg-gray-700  border-gray-600  focus:ring-primary-600  ring-offset-gray-800" required="" />
                              </div>
                              <div className="ml-3 text-sm">
                                <label for="remember" className="text-gray-500  text-gray-300">Remember me</label>
                              </div>
                          </div> */}
                          {/* <a href="#" className="text-sm font-medium text-[#ff9b53] hover:underline  text-primary-500">Forgot password?</a> */}
                      {/* </div> */}
                      {/* <Link to={"/"} className='outline-none'> */}
                      <button type="submit" className="w-full text-white border-2 border-slate-600 hover:bg-slate-900 font-medium rounded-full text-sm px-5 py-2.5 text-center  bg-primary-600  hover:bg-primary-700 hover:scale-[1.02] hover:shadow-xl transition-all duration-75" onClick={handleSubmit}>Login</button>
                      {/* </Link> */}
                      <p className="text-sm font-light text-gray-400">
                          Don’t have an account yet? <Link to={"/signup"} className="font-medium text-primary-600 hover:underline  text-primary-500">Sign Up</Link>
                      </p>
                  </form>
              </div>
          </motion.div>
      </div>
    </section>
    </>
  )
}

export default Login