import React from 'react'
import AdminMenu from './AdminMenu'
import AdminHeader from './AdminHeader'
import { Outlet } from 'react-router-dom'

const Admin = () => {
  return (
    <>
    <div className='container m-auto px-4'>
        <AdminHeader />
        <AdminMenu />
        <Outlet />
    </div>
    </>
  )
}

export default Admin