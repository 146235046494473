import React from 'react'
import courseImg from "../../assets/images/frontend-course.png"
import { MdCreateNewFolder } from "react-icons/md";
import { CgEditFade, CgPen } from 'react-icons/cg';

const AdminManageCourses = () => {
  return (
    <>
    <h1 className='text-2xl my-6 font-bold'>Manage Your Courses</h1>
      <div className="bg-slate-700 my-6 rounded-md p-4 flex gap-4">
        <div className="course-img w-2/5">
          <img src={courseImg} alt=""  className='rounded-md'/>
        </div>
        <div className="course-details w-3/5">
          <div className="title">
            <h1 className='text-2xl font-medium'>Frontend Web Development Course - 3 Month Program with Mentorship Support</h1>
            <span className='text-slate-300'>
              Lorem ipsum dolor sit amet consectetur adipisicing elit. Ad blanditiis nobis veritatis numquam quasi hic alias minus aut, voluptatum nulla quod officiis harum eligendi quia delectus molestiae provident qui laborum
            </span>
          </div>
          <div className="flex mt-2 gap-4">
            <div className="price bg-slate-600 border-2 cursor-pointer border-slate-400 p-3 flex flex-col justify-center items-center rounded-md">
              <h1 className='text-2xl font-semibold'>100+</h1>
              <span>Active Students</span>
            </div> 
            <div className="price bg-slate-600 border-2 cursor-pointer border-slate-400 p-3 flex flex-col justify-center items-center rounded-md">
              <h1 className='text-2xl font-semibold text-red-400'>₹3999</h1>
              <span>Actual Price</span>
            </div> 
            <div className="price bg-slate-600 border-2 cursor-pointer border-slate-400 p-3 flex flex-col justify-center items-center rounded-md">
              <h1 className='text-2xl font-semibold text-green-400'>₹3999</h1>
              <span>Current Price</span>
            </div> 
            <div className="price bg-slate-600 border-2 cursor-pointer border-slate-400 p-3 flex flex-col justify-center items-center rounded-md">
              <h1 className='text-2xl font-semibold'>12+</h1>
              <span>Weeks</span>
            </div> 
          </div>
        </div>
        <div className="course-details w-1/5 flex justify-center flex-col items-center gap-4">
          <div className='border-2 p-2 rounded-md border-slate-500 cursor-pointer hover:bg-slate-800 transition-all flex items-center gap-2'>
            <CgPen/>Edit Details
          </div>
          <div className='border-2 p-2 rounded-md border-slate-500 cursor-pointer hover:bg-slate-800 transition-all flex items-center gap-2'>
            <CgPen/>Add Week
          </div>
          <div className='border-2 p-2 rounded-md border-slate-500 cursor-pointer hover:bg-slate-800 transition-all flex items-center gap-2'>
            <CgPen/>Manage Content
          </div>
        </div>
      </div>

      <div className="bg-slate-800 border-2 cursor-pointer hover:scale-[1.009] transition-all hover:bg-slate-900 border-dashed border-slate-600 my-6 rounded-md p-4 flex justify-center items-center gap-4 h-60">
        <h1 className='text-slate-500 text-2xl font-bold'>Create More +</h1>
      </div>
    </>
  )
}

export default AdminManageCourses