import React from 'react'
import { IoIosSend, IoMdSend } from "react-icons/io";


const AdminSendAnnouncement = () => {
  return (
    <>
    <div className="message mt-6">
      <h1 className='text-2xl font-bold flex items-center justify-center gap-1'><IoIosSend /> Send Your Anouncement</h1>
      <div className="message-send rounded-md p-4 mt-2">
      <form className="max-w-xl mx-auto bg-slate-700 p-4 rounded-3xl border-2 border-slate-500">

        <label for="message" className="block mb-2 text-xl font-medium text-gray-900 dark:text-white mt-2">Select your course <span className='text-red-400'>**</span></label>
        <select placeholder='Enter your link' className='block p-2.5 w-full text-sm text-gray-900 bg-gray-50 rounded-lg border border-gray-300 focus:ring-orange-500 focus:border-orange-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-orange-500 dark:focus:border-orange-500'>
          <option value="0">Choose your course</option>
          <option value="0">Frontend Web Development Course</option>
          <option value="0">Business Development</option>
          <option value="0">Backend Web Development</option>
        </select>

        <label for="message" className="block mb-2 text-xl font-medium text-gray-900 dark:text-white mt-4">Topic <span className='text-slate-500'>(Eg. LIVE SESSION)</span></label>
        <input type="text" placeholder='Enter your link' className='block p-2.5 w-full text-sm text-gray-900 bg-gray-50 rounded-lg border border-gray-300 focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500' />

        <label for="message" className="block mb-2 text-xl font-medium text-gray-900 dark:text-white mt-4">Add a Link <span className='text-slate-500'>(Eg. Meeting/Resouses)</span></label>
        <input type="text" placeholder='Enter your link' className='block p-2.5 w-full text-sm text-gray-900 bg-gray-50 rounded-lg border border-gray-300 focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500' />

        <label for="message" className="block mb-2 text-xl font-medium text-gray-900 dark:text-white mt-4">Your message</label>
        <textarea id="message" rows="6" className="block p-2.5 w-full text-sm text-gray-900 bg-gray-50 rounded-lg border border-gray-300 focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" placeholder="Type your message..."></textarea>

        <div className="button mt-4 bg-slate-600 hover:bg-orange-500 transition-all cursor-pointer rounded-xl px-4 py-2 w-auto border-2 border-slate-500">
          <button className='flex items-center gap-1'><IoIosSend /> Leave Anouncement</button>
        </div>
      </form>
      </div>
    </div>
    </>
  )
}

export default AdminSendAnnouncement