import React, { useContext, useState } from "react";
import { useUserAuth } from "../../context/UserAuthContext";
import { useNavigate } from "react-router-dom";
import { IoLogOutOutline } from "react-icons/io5";
import { RiMenu2Line } from "react-icons/ri";
import { FaUserCircle } from "react-icons/fa";
import { MdArrowDropDown } from "react-icons/md";
import { SidebarState } from "../../pages/Dashboard/Layout";
import logo from "../../assets/images/logo_wide.png";
import Search from "./Search";
import { useMobileScreenContext } from "../../context/MobileScreen";
import { TiThMenu } from "react-icons/ti";
import { HiOutlineSearch } from "react-icons/hi";

const Header = () => {
  const { isMobile, setIsMobileNavbar, isMobileNavbar, toggleIsMobileNavbar } =
    useMobileScreenContext();
  const { ToggleButtonSidebar, isOpen, setIsOpen } = useContext(SidebarState);

  const { logOut, user } = useUserAuth();
  const navigate = useNavigate();
  const handleLogout = async () => {
    try {
      await logOut();
      navigate("/login");
    } catch (error) {
      console.log(error.message);
    }
  };

  return (
    <>
      {isMobile ? (
        <div className="mobileHeader p-2 px-4 flex justify-between text-4xl h-auto">
          <div
            className="flex items-center hover:text-slate-500"
            onClick={toggleIsMobileNavbar}
          >
            <TiThMenu />
          </div>
          <div className="flex items-center justify-center">
            <img src={logo} alt="" className="h-12" />
          </div>
          <div className="flex items-center bg-slate-800 px-2 rounded-xl">
            <img src={user.photoURL} alt="" className="h-10 rounded-full p-1" />
          </div>
        </div>
      ) : null}

      {!isMobile ? (
        <div className="emailshow px-4 h-auto py-2 flex justify-between items-center container m-auto">
          {!isOpen ? (
            <div className="flex justify-center items-center">
              <img src={logo} alt="" className="h-10" />
            </div>
          ) : null}

          <div className="flex justify-center items-center bg-slate-700 px-4 rounded-full border-2 border-slate-600">
            <div className="search-icon px-2">
              <HiOutlineSearch className="text-slate-400" />
            </div>
              <Search />
          </div>

          <div className="cursor-pointer flex justify-center items-center gap-2 hover:bg-slate-700 rounded-md px-3 transition-all h-9 border-2 border-gray-700">
            {/* <p className='text-slate-400 hover:text-slate-300 transition-all'>{user && user.email}</p> */}
            <img
              src={user.photoURL}
              alt=""
              className="h-full rounded-full p-1"
            />
            <h1 className="text-slate-400">{user.email}</h1>
            <MdArrowDropDown className="text-2xl text-slate-400" />
          </div>
        </div>
      ) : null}
    </>
  );
};

export default Header;
