import React, { useContext } from 'react';
import logo from "../../assets/images/logo_wide.png";
import { Link, useNavigate } from "react-router-dom";
import { useUserAuth } from '../../context/UserAuthContext';
import "./sidebar.css";
import { IoArrowBack, IoArrowForward, IoOpen } from 'react-icons/io5';
import { IoIosDesktop } from 'react-icons/io';
import BroadMenu from './BroadMenu';
import { SidebarState } from '../../pages/Dashboard/Layout';
import { IoMenu } from "react-icons/io5";
import SmallMenu from './SmallMenu';
import { motion } from 'framer-motion';

function Sidebar() {

  
  const {ToggleButtonSidebar, isOpen, setIsOpen} = useContext(SidebarState);

  const navigate = useNavigate();

  const container = {
    // hidden: { 
    //   opacity: 0,
    // },
    // show: {
    //   opacity: 1,
    //   x: 0,
    //   transition: {
    //     delayChildren: 0.5
    //   }
    // }
  }


  return (
    <>
{isOpen?    

<motion.aside id="default-sidebar" className="fixed top-0 left-0 z-40 w-60 h-screen transition-transform -translate-x-full sm:translate-x-0" aria-label="Sidebar"
  variants={container}
  initial="hidden"
  animate="show"
>
    <div className="toggle-sidebar h-full px-3 py-4 overflow-y-auto bg-gray-50 dark:bg-slate-800">
            <div className='flex justify-between items-center mb-4 mt-2'>
              <div>
              <Link to={"/"}>
                <img src={logo} alt="Logo" className='h-12' />
              </Link>
              </div> 
                <div onClick={ToggleButtonSidebar} className="text-slate-400 animate-pulse border-gray-500 hover:text-slate-200 cursor-pointer transition-all flex justify-center items-center bg-slate-900 hover:bg-slate-700 rounded-full p-2">
                      {isOpen ? <IoArrowBack className='text-[20px]' /> : <IoArrowForward className='text-[20px]' />}
                </div>
            </div>

        <BroadMenu />
 
    </div>
  </motion.aside> 
  
  :

  <aside id="default-sidebar" className="fixed top-0 left-0 z-40 w-[65px] h-screen transition-transform -translate-x-full sm:translate-x-0" aria-label="Sidebar">
    <div className="toggle-sidebar h-full px-3 py-4 overflow-y-auto bg-gray-50 dark:bg-slate-800">
        <div className='flex justify-between items-center mb-4 mt-2'>
            <div onClick={ToggleButtonSidebar} className="text-slate-400 border-gray-500 hover:text-slate-200 cursor-pointer transition-all flex justify-center items-center bg-slate-900 hover:bg-slate-700 rounded-full p-2">
                  {isOpen ? <IoArrowBack className='text-[20px]' /> : <IoArrowForward className='text-[20px]' />}
            </div>
        </div>

        <SmallMenu />
 
    </div>
  </aside> 


}
</>
)
}

export default Sidebar