import React, { useEffect, useState } from 'react'
import Message from './Message'
import { collection } from 'firebase/firestore';

export const LatestUpdate = () => {
  const [LatestUpdate, setLatestUpdate] = useState([]);

  const getLatestUpdates = () => {
    // get the updates from the date base of firestore and then set it into the latest update state
    try {
      
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getLatestUpdates();
  }, []);
  return (
    <div className='bg-[#1a2d48d9] mt-4 rounded-xl p-4'>
        <div className="header">
            <h1 className='text-2xl'>Latest Updates</h1>
            <hr className='mt-3 opacity-10' />
        </div>
        <Message />
        <Message />
    </div>
  )
}
